import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';

//import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
//Other components
import NavBar from "../components/NavbarContainer";

//API

import { queryCompletedTrips, getTripSummary } from "../api/services/programmerAPI"

/*
const viajes = [
    'Resumen_Colima_Cuauhtemoc_1400_27022023',
    'Resumen_Colima_Cuauhtemoc_1401_27022023',
    'Resumen_Colima_Cuauhtemoc_1402_27022023',
    'Resumen_Colima_Cuauhtemoc_1403_27022023',
    'Resumen_Colima_Cuauhtemoc_1404_27022023',
]*/


const Page7 = () => {

    const [viajes,setViajes]=useState([])
    
    const [openAlert, setOpenAlert] = useState(false);
    const [globalError, setGlobalError] = useState('');
    const [summary, setSummary] = useState({})


    const downloadExcel = (jsonObject, fileName) => {
        
        const hoja1 = {
            idviaje:jsonObject.idviaje,
            partida:jsonObject.partida,
            destino:jsonObject.destino,
            fecha:jsonObject.fecha.split('T')[0],
            hora_de_partida:jsonObject.hora_de_partida,
            hora_de_llegada:jsonObject.hora_de_llegada,
            personas_pasajeras:jsonObject.personas_pasajeras,
            status: "Viaje completado",
            hora_real_partida:jsonObject.hora_real_partida,
            hora_real_llegada:jsonObject.hora_real_llegada,
        }

        const hoja2={
            personas_pasajeras:jsonObject.personas_pasajeras,
            asientos_ocupados:jsonObject.ocupados,
            asientos_libres:jsonObject.libres,
            boletos_no_utilizados: jsonObject.comprado,
            boletos_utilizados: jsonObject.completado,
            usuarios_femeninos:jsonObject.femenino,
            usuarios_masculinos:jsonObject.masculino,
            usuarios_primer_semestre:jsonObject.primer_semestre,
            usuarios_segundo_semestre:jsonObject.segundo_semestre,
            usuarios_tercer_semestre:jsonObject.tercer_semestre,
            usuarios_cuarto_semestre:jsonObject.cuarto_semestre,
            usuarios_quinto_semestre:jsonObject.quinto_semestre,
            usuarios_sexto_semestre:jsonObject.sexto_semestre,
        }

        const hoja3 = jsonObject.municipios;

        const hoja4 = {
            
            operador: jsonObject.nombre + ' ' + jsonObject.apellido_p + ' ' + jsonObject.apellido_m,
            email_operador: jsonObject.email,
            placa:jsonObject.placa,
            numero_esconomico:jsonObject.num_economico
        }
        
        
        const wb = XLSX.utils.book_new();

        const ws1 = XLSX.utils.json_to_sheet([hoja1]);
        const ws2 = XLSX.utils.json_to_sheet([hoja2]);
        const ws3 = XLSX.utils.json_to_sheet(hoja3);
        const ws4 = XLSX.utils.json_to_sheet([hoja4]);
        
        XLSX.utils.book_append_sheet(wb, ws1, 'Datos del viaje');
        XLSX.utils.book_append_sheet(wb, ws2, 'Estadistica General');
        XLSX.utils.book_append_sheet(wb, ws3, 'Estadistica municipios');
        XLSX.utils.book_append_sheet(wb, ws4, 'Información del operador');


        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
        };
        saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        
      };

    const getStats = async (idviaje, fileName)=>{
        let tripObj = {idviaje:idviaje}
        const summaryResponse = await getTripSummary(tripObj)
        console.log(summaryResponse)
        downloadExcel(summaryResponse.data.data,fileName)
    }

    const getTrips = async ()=>{
        let tripsResponse;
        try{

            tripsResponse = await queryCompletedTrips();
            //console.log(tripsResponse)
            setViajes(tripsResponse.data.data)

        }catch(err){
            setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
            setOpenAlert(true)
            console.log(err)
        }
    }


    useEffect(()=>{
        getTrips()

    },[])


    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} style={{marginBottom: '20px', padding: '30px'}}>
                <Grid item xs={12}>
                    <Typography>Resumenes de viajes</Typography>
                </Grid>
                <Grid item>
                    {
                        viajes.map((viaje, index) => (
                            <Grid item xs={12} key={viaje.idviaje}> 
                                
                                <Link onClick={()=>{getStats(viaje.idviaje,viaje.fecha.split('T')[0])}}>{viaje.fecha.split('T')[0]} de {viaje.partida} a {viaje.destino} a las {viaje.hora_de_partida}</Link>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </>
    )

}

export default Page7;