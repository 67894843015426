import React, {useState, useEffect} from "react";


//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';

//Router
import { useNavigate } from "react-router-dom";

const CardsContainer = ({cards}) => {

    const navigate = useNavigate()

    return (
        <Grid item container spacing={3} justifyContent='space-evenly'>
            {
 cards.map((card, index) => (
    <Grid container item md={3} key={index} justifyContent="center" style={{borderRadius: 50, cursor: 'pointer' }} >
            <div sx={{ width: 345, height: 400, border: 'none' }} onClick={() => navigate(card.path)}>
                <img src={card.img} alt={card.img} width={300}/>
                <Typography gutterBottom variant="h5" component="div" style={{textAlign: 'center', color: 'black', fontWeight: 900}}>
                    {card.title}
                </Typography>
            </div>
        </Grid>
))
            }
       
        </Grid>
    )

}

export default CardsContainer;