import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import QRCode from "react-qr-code";

import qr from '../static/images/cards/qr.png'

import { useNavigate, Link } from 'react-router-dom';

function printElement(elem) {
  var domClone = elem.cloneNode(true);
  
  var $printSection = document.getElementById("printSection");
  
  if (!$printSection) {
      var $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
  }
  
  $printSection.innerHTML = "";
  $printSection.appendChild(domClone);
  window.print();
}

export default function ImprimirDialog({open, setOpen, hashes}) {
  const navigate = useNavigate();

  const [style,setStyle] = React.useState({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '50vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  });

  //const [open, setOpen] = React.useState(false);
  
  const handleClose = () => 
  {setOpen(false)
    return navigate(`/home`)
  };



  const handlePrint = () => {

    
    setStyle(prevState => ({...prevState, width: 'auto',
      height: 'auto', maxHeight:'auto',
      overflow: 'visible' }))
    console.log('imprimir');
    
    
    window.print()

    setStyle(prevState => ({...prevState, width: 400,
      height: 'auto', maxHeight:'50vh',
      overflow: 'auto' }))

      //printElement(document.getElementById("printThis"));

  }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Imprime tus boletos"}
        </DialogTitle>
        <DialogContent>
        <Grid container direction="column" justifyContent="center" alignItems="center" >
        
        <Grid item xs={8} id="printThis" style={{overflow: 'auto'}}>
          
          {
            hashes.map((hash)=>(
              <>
              <Typography key={hash.idboleto + 'typo'} variant="subtitle1" >
                Viaje de {hash.partida} a {hash.destino} el día {hash.fecha.split('T')[0]} a las {hash.hora_de_partida}
              </Typography>
              <QRCode key={hash.idboleto} value={hash.hash} />
              </>

            ))
          }
          
        </Grid>
       
        <Grid item xs={8}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Ahora ya puedes imprimir tu boleto, da click en el siguiente botón.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" color="success" onClick={handlePrint} style={{marginTop: '10px'}}>Imprimir</Button>

        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
  );
}