import { useState, useEffect } from 'react'
import { useNavigate, NavLink, useMatch} from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import MenuListComposition from './MenuListComposition';


// Icons
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HouseIcon from '@mui/icons-material/House';
import WidgetsIcon from '@mui/icons-material/Widgets';

import CarCrashIcon from '@mui/icons-material/CarCrash';
import AllInboxIcon from '@mui/icons-material/AllInbox';

// LOGOS
//import simoslogo from '../../assets/headerSIMOS.png';



const NavButton = (props) => {
  const { path, children, to, addSX, ...rest } = props
  const match = useMatch({ path })

  

  return (
    <Link
      to={to}
      component={NavLink}
      variant="subtitle1"
      underline="none"
      sx={{
        pt: '5px',
        px: '25px',
        height: '100%',
        align: 'center',
        display: 'flex',
        lineHeight: '28px',
        alignItems: 'center',
        letterSpacing: '1px',
        flexDirection: 'column',
        fontWeight: match ? '600' : '500',
        color: (theme) =>
          match
          ? theme.palette.secondary.dark
          : 'white'
        ,
        borderBottom: (theme) =>
          match
          ? `3px solid ${theme.palette.secondary.dark}`
          : `3px solid transparent`
        ,
        transition: '.3s',
        transitionProperty: 'background, color, borderBottom',
        '&:hover': {
          fontWeight: '600',
          color: (theme) => theme.palette.primary.light,
          borderBottom: (theme) => `3px solid ${theme.palette.primary.light}`,
        },
        ...addSX,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

const NavMenu = (props) => {
  const { children, addSX, ...rest } = props

  return (
    <Link
      variant="subtitle1"
      underline="none"
      sx={{
        pt: '5px',
        px: '25px',
        height: '100%',
        align: 'center',
        display: 'flex',
        lineHeight: '28px',
        alignItems: 'center',
        letterSpacing: '1px',
        flexDirection: 'column',
        fontWeight: '500',
        color: 'white'
        ,
        borderBottom: `3px solid transparent`
        ,
        transition: '.3s',
        transitionProperty: 'background, color, borderBottom',
        '&:hover': {
          fontWeight: '600',
          color: (theme) => theme.palette.primary.light,
          borderBottom: (theme) => `3px solid ${theme.palette.primary.light}`,
        },
        ...addSX,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}


const DesktopMenu = props => {
  const { level } = props
  const navigate = useNavigate();
  const [incidentMenu, setIncidentMenu] = useState('');
  const [routeSelection, setRouteSelection] = useState('');

  const indentsOptions = [{route:"/nuevo-registro",name:"Nuevo Registro"},{route:"/mis-registros", name:"Ver Mis Registros"}, {route:"/incidents", name:"Ver Todos los Registros"}];
  const userOptions = [{route:"/newuser",name:"Registrar nuevo usuario"}, {route:"/users",name:"Administrar usuarios"}]


  const handleChangeIncident = (event) => {
    setIncidentMenu(event.target.value);
  };

  const [openModal, setOpenModal] = useState(false);

  const styles={
    simosstyles:{
        height: "1%"
        
      },
    select: {
        '&:before': {
            borderColor: "pink",
        },
        '&:after': {
            borderColor: "pink",
        }
    },
    icon: {
        fill: "pink",
    },
  }

  //Effects
  useEffect(() => {
    
    if(routeSelection !== null){
    // console.log("Se seleccionó ", routeSelection)
    //Aqui hay que hacer el redireccionamiento
    }
  
  }, [routeSelection])
  

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-evenly',
        px: 'auto',
      }}
    >
      <Typography
        // noWrap
        variant="h5"
        component="div"
        onClick={() => navigate('/home')}
        sx={{
          // mx: 2,
          ml: { md: -4.5, xl: -9 },
          cursor: 'pointer',
          alignSelf: 'center',
          justifyContent:"center",

          display: { xs: 'none', md: 'flex' },
          color: 'black',
          fontWeight: 900
        }}
      >
        Ruta <span style={{fontWeight: 100}}>ESCOLAR</span>
      </Typography>
      
      <Box
        sx={{
          gap: 3,
          alignItems: 'stretch',
          justifyContent: 'center',
          display: { xs: 'none', md: 'flex' },
          zIndex: "100",
          fontWeight: 900
        }}
      >
        {level ===0 &&
        <>

            <NavButton
            style={{fontWeight: 800}}
            path="/page6"
            to="/page6"
            
            >
            Mis boletos
            </NavButton>
            <NavButton
            style={{fontWeight: 800}}
            path="/page9"
            to="/page9"
            >
            Consultar Disponibilidad
            </NavButton>
            


        </>
        }
        
        {level === 1 &&
        <>
        <NavButton
        style={{fontWeight: 800}}
            path="/page1"
            to="/page1"
            >
            Escanear
            </NavButton>
            
        </>
        }

        {level === 2 &&
        <>
            <NavButton
            style={{fontWeight: 800}}
            path="/page2"
            to="/page2"
            >
            Horarios
            </NavButton>
            <NavButton
            style={{fontWeight: 800}}
            path="/page7"
            to="/page7"
            >
            Resumenes
            </NavButton>

        </>
        }
        {level === 3 &&
        <>
            <NavButton
            style={{fontWeight: 800}}
            path="/reservarviaje"
            to="/reservarviaje"
            >
            Reservar
            </NavButton>
            <NavButton
            style={{fontWeight: 800}}
            path="/page9"
            to="/page9"
            >
            Consultar Disponibilidad
            </NavButton>

            <NavButton
            style={{fontWeight: 800}}
            path="/page7"
            to="/page7"
            >
            Resúmenes
            </NavButton>

        </>
        }
        

      </Box>
        

      
      { /* <img src={simoslogo}  alt="WRI logo" style={{height:"5vh"}} />
        */}

      
            
    
    </Box>
  )
}

export default DesktopMenu