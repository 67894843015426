import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { alpha, styled } from '@mui/material/styles';
import { Grid, Typography, TextField, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



import Swal from "sweetalert2";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


//Other components
import NavBar from "../components/NavbarContainer";

import { formatDate, formatTime } from "../helpers";

//API
import { createReservation } from "../api/services/reservasAPI"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  
const viajesDisponibles = [
    'Colima_Cuauhtemoc_(04/11/2023T10:00)',
    'Colima_INSENCO_(04/11/2023T10:00)',
    'INSENCO_Colima_(04/11/2023T10:00)',
]
const lugares = [
    'Colima',
    'Cuahtemoc',
    'Insenco'
]

const weekDays = ["D", "L", "MA", "MI", "J", "V", "S"]
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

/*
const myViajes = [
    {
        id: 1,
        nombre: 'COLIMA - INSENCO',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },{
        id: 2,
        nombre: 'INSENCO - COLIMA',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },{
        id: 3,
        nombre: 'COLIMA - INSENCO',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },
    {
        id: 4,
        nombre: 'INSENCO - COLIMA',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },
]
*/

const CssTextField = styled(TextField)({
    // '& label': {
    //   color: 'transparent'
    // },
    borderRadius: '4px',
    border: '#C1C1C1 1px solid',
    '& .MuiFormHelperText-root': {
      color: 'red',
      paddingLeft: '2%',
      fontSize: '14px'
    },
    
    '& label.MuiInputLabel-shrink': {
      color: 'transparent',
    },
    
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  });

function getStyles(name, personName, theme) {
    return {
    fontWeight:
        personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    };
}
  

const Page5 = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const theme = useTheme();
    
    const [matricula, setMatricula] = useState('');
    const [myViajes, setMyViajes] = useState([]);
    //const [email, setEmail] = useState('');
    const [ruta, setRuta] = useState('0');
    const [viajes, setViajes] = useState([]);
    const [dia, setDia] = useState('');
    const [horario, setHorario] = useState('');
    const [loading, setLoading] = useState(false);
    const [rangeDate, setRangeDate] = useState([today, tomorrow]);
    

    const [dias, setDias] = useState({
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false
    });
    const [horarios, setHorarios] = useState({
    col1: false,
    col2: false
    });

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [globalError, setGlobalError] = useState('');

    const [errorMatricula, setErrorMatricula] = useState('');


    // ------------------ Handlers -------------
    const handleOpen = () => setOpen(true);

    const handleClose = () => {

        setOpenAlert(false)

    };
    const filterSelected = (array1, array2) => {

        let newArray = []
        for(let i = 0; i < array2.length; i++) {
            if(array2[i])  newArray.push(array1[i])
        }

        return newArray;
    }
    const handleSubmit = async () => {
    
        setLoading(true)

        let error = false;

        
    
        

        if(error) {
            setOpenAlert(true)
          } else {
            

            //Guardar reservacion
            try {
                //console.log(myViajes[0].idviaje)
                
                const response = await createReservation({"idviaje":myViajes[0].idviaje})
                setGlobalError({ open: true, msg: response.data.msg, key: new Date(), status:response.data.status })
                setOpenAlert(true)
                 console.log(response)
                 //Redirigir al home
                 setTimeout(()=> {
                    navigate('/home');
                   }, 1500);
                
              } catch (err) {
                setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                setOpenAlert(true)
                console.log(err)
              }

            
            
            
            
          }
    
       // dispatch(deleteInfo())
    
       /* 
       try{
            const response = await login(dataUser)
            console.log(response)
            Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Bienvenido!',
            showConfirmButton: false,
            timer: 1500
            })
            return navigate(`/mis-lugares`)
            }
            catch(error){
            //setMessage({ open: true, msg: error.data.msg })
            setOpen(true)
            }
        
        */
       
        setLoading(false)
        
        //handleOpen()
        
    
    }
    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setViajes(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    /*const handleChange = (event) => {
        setDias({
            ...dias,
            [event.target.name]: event.target.checked,
        });
    };
    */
    const handleChange1 = (event) => {
        setHorarios({
            ...horarios,
            [event.target.name]: event.target.checked,
        });
    };
    const { lunes, martes, miercoles, jueves, viernes } = dias;
    const { col1, col2 } = horarios;

    const handleRuta = (e) => {
        setRuta(e.target.value)
    }
    const handleMatricula= (e) => {
        setMatricula(e.target.value);
        setErrorMatricula('');
    }
    const handleDate = (e) => {
        setRangeDate(e)
    }

    const clear = () => {
        setMatricula('')
        setRuta('0')
        setDias({
            lunes: false,
            martes: false,
            miercoles: false,
            jueves: false,
            viernes: false
        })
        setHorarios({
            col1: false,
            col2: false
        })
        setErrorMatricula('')
       
    }

    const handleCancel = () => {

        Swal.fire({
            title: '¿Estás seguro de cancelar?',
            text: "Una vez aceptado, se borrará la información agregada anteriormente",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Regresar',
            confirmButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clear()
              navigate('/home')
            }
          })
    }
    
    //Use Effects
    useEffect(() => {
        console.log(state)
        if(state){
            console.log("Contenido")
            setMyViajes(oldArray => [...oldArray, state.trip])
        
        }else{
            navigate('/page9')
            console.log("navegacion")
        }

        window.history.replaceState({}, document.title)
      }, []);

    return (
        <>
            <NavBar />
            <marquee style={{color:"red"}}>Horarios de atención: Biblioteca Cuauhtémoc de 7:00 am a 8:00 pm y Frente a la dirección en Colima de 6:00 am a 4:00 pm</marquee>
            <Grid container spacing={2} justifyContent="center" style={{padding: '20px'}}>
                
                
                
            
                
                
                <Grid item md={7}>
                    <Typography>1. Selecciona los viajes por pagar</Typography>
                </Grid>
                <Grid item md={7}>
                    {
                        myViajes.map(viaje => (
                            <Alert 
                                severity="warning" 
                                key={viaje.idviaje} 
                                style={{marginBottom: '15px', backgroundColor: '#E8DDFC'}}
                            >
                                <AlertTitle>{viaje.partida} - {viaje.destino}</AlertTitle>
                                <Checkbox defaultChecked />{/** Este checkbox no hace nada???? */}
                                {viaje.fecha.split('T')[0]} a las {viaje.hora_de_partida} — <strong>Por favor da click en aceptar y procede a pagar el viaje en caja</strong>
                            </Alert>
                        ))
                    }
                    
                </Grid>
                <Grid item md={7}>
                    <Typography>2. Total a pagar</Typography>
                </Grid>
                <Grid item md={7}>
                    <Typography>El costo de los viajes reservados es de: <span style={{fontWeight: 'bold', fontSize: '22px'}}>$5 MXN</span></Typography>
                </Grid>
                <Grid item md={7}>
                    <Typography variant="button" gutterBottom color="red">¡Importante!: Recuerda que tienes 4 horas para pagar tu reserva. </Typography>
                    
                </Grid>

                
                <Grid container spacing={2} justifyContent="center" style={{marginTop: 20}}>
                <Grid item md={2}>
                    <Button variant="contained" color="success" onClick={handleSubmit}>Aceptar</Button>
                </Grid>
                <Grid item md={2}>
                    <Button variant="contained" color="error" onClick={handleCancel}>Cancelar</Button>
                </Grid>
            </Grid>
               
                
            </Grid>

            <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
                
            >
                <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
            </Snackbar>


            
        </>
    )

}

export default Page5;