import React, {useState, useEffect} from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import QRCode from "react-qr-code";


//Other components
import NavBar from "../components/NavbarContainer";
import { formatDate } from "../helpers";

//API
import { getMyTickets, getMyReservations } from '../api/services/userAPI';


import qr from '../static/images/cards/qr.png'


const viajes = [
    'viaje1',
    'viaje2',
    'viaje3',
    'viaje4'
]

const opciones = [
    'Boletos comprados',
    'Viajes reservados'
]


const Page6 = () => {

    const [viaje, setViaje] = useState('0');
    const [opt, setOpt] = useState('0');
    const [flag, setFlag] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [reservations, setReservations] = useState([]);

    const [openAlert, setOpenAlert] = useState(false);
    const [globalError, setGlobalError] = useState('');

    const handleChange = (e) => {
        
        setViaje(e.target.value);
    }

    const handleOptChange = (e) => {
        
        setOpt(e.target.value);

        console.log(e)
    }

    const handleClose = () => {

        setOpenAlert(false)

    };

    const getTickets = async()=>{

        try{
            const responseTickets = await getMyTickets()

            console.log(responseTickets)

            setTickets(responseTickets.data.data)

        }catch(err){
            setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                setOpenAlert(true)
                console.log(err)
        }

    }

    const getReservations = async()=>{
        try{
            const responseReservations = await getMyReservations()

            console.log(responseReservations)

            setReservations(responseReservations.data.data)

        }catch(err){
            setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                setOpenAlert(true)
                console.log(err)
        }
    }

    useEffect(()=>{
        if(opt === 'Boletos comprados'){
            setFlag(1);
            getTickets()

        }else if(opt === 'Viajes reservados'){
            setFlag(2)
            getReservations()
        }else{
            setFlag(0)
        }
    },[opt])

    

    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} justifyContent="center" style={{padding: '50px'}}>
            <Grid container justifyContent="center">
                
            <Grid item md={6}>
                    <Typography>Selecciona una opción:</Typography>
                </Grid>
                <Grid item md={4}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={opt}
                        label="Opciones"
                        onChange={handleOptChange}
                    >
                        <MenuItem value={'0'}><em>Selecciona una opcion</em></MenuItem>
                        {
                            opciones.map((opcion, index) => (
                                <MenuItem key={index} value={opcion}>{opcion}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                        <br/><br/>
                {/*
                    flag === true &&
                    <>
                    <Grid item md={6}>
                    <Typography>Selecciona uno de los viajes activos:</Typography>
                </Grid>
                <Grid item md={4}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={viaje}
                        label="Viaje"
                        onChange={handleChange}
                    >
                        <MenuItem value={'0'}>Selecciona un viaje</MenuItem>
                        {
                            viajes.map((viaje, index) => (
                                <MenuItem key={index} value={viaje}>{viaje}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                    </>
                    */}
                <Grid item container spacing={2} justifyContent="center" direction="column">
                <Grid item md={2}>
                {flag === 1 &&
                        <>
                        <Typography variant="h6">Tus Boletos:</Typography>
                        <Typography variant="subtitle2">Utiliza estos codigos QR para abordar tu viaje.</Typography>
                        </>
                    }
                </Grid>
                <Grid item md={6}>
                {
                    flag === 1 &&
                    
                        tickets.map((ticket,index)=>(
                            <>
                            
                            <Typography>Viaje de {ticket.partida} a {ticket.destino} el día {ticket.fecha.split('T')[0]} a las {ticket.hora_de_partida}. Tu boleto es: </Typography>
                        
                        
                        <QRCode key={ticket.idboleto} value={ticket.hash} />
                        <br></br>
                        <br></br>
                        <br></br>
                        
                            </>
                        ))
                        
                }
                </Grid>

                    
                {flag === 2 &&
                    <Grid item md={2}>
                        <Typography variant="h6">Tus reservaciones:</Typography>
                        <Typography variant="subtitle2">Estos no son tus boletos, son reservaciones que debes pagar en caja. No podras abordar el viaje sin tu codigo QR.</Typography>
                    </Grid>
                }
                    
                <Grid item md={6}>
                { flag === 2 ?
                    

                        reservations.map((ticket,index)=>(
                            
                            
                            <Typography>Viaje de {ticket.partida} a {ticket.destino} el día {ticket.fecha.split('T')[0]} a las {ticket.hora_de_partida}.  </Typography>
                        
                        
                        ))
                    
                    :

                    <></>
                        
                    

                }
                </Grid>
                </Grid>


                
            </Grid>
            </Grid>

            <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
                
            >
                <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
            </Snackbar>
        </>
    )

}

export default Page6;