import axios, { instanceI as axiosImages } from '../axiosConfig';

  


export const queryAllTrips = async() => {
    return await axios.get(`/programmer/queryalltrips`)
  }

  export const createTrips = async(data) => {
    return await axios.post(`/programmer/newtrip`,data)
  }

  export const queryCompletedTrips = async() => {
    return await axios.get(`/programmer/getcompletedtrips`)
  }
  
  export const getTripSummary = async(data) => {
    return await axios.post(`/programmer/gettripsummary`,data)
  }

  export const editTripService = async(data) => {
    return await axios.post(`/programmer/edittrip`,data)
  }

  export const deleteTrip = async(data) => {
    return await axios.post(`/programmer/deletetrip`,data)
  }