
import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import img2 from '../static/images/cards/camion.png'

// import Typography from '@mui/material/Typography';


import Swal from 'sweetalert2'
import { getUserById, editUser } from '../api/adminAPI';


import {municipiosArray, coloniasArray} from '../utils/municipios'


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const CssTextFieldP = styled(OutlinedInput)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red'
  },
  '& label.MuiOuInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
const CssSelect = styled(Select)({
   '& .MuiSelect-select': {
    '& select': {
      borderColor: 'transparent',
    },
    '&:hover select': {
      borderColor: 'transparent',
    },
    '&.Mui-focused select': {
      borderColor: 'transparent',
    },
   },
   
})

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


export default function EditUser() {

    let { userRol, userId } = useParams();
    console.log(userRol);

  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate();

  //States
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [apellidoM, setApellidoM] = useState('');
  const [matricula, setMatricula] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [sexo, setSexo] = useState('');
  const [genero, setGenero] = useState('');
  const [munCode, setMunCode] = useState('');
  const [localidad, setLocalidad] = useState('localidad1');
  const [municipio, setMunicipio]= useState('');
  const [colonia, setColonia] = useState('');
  const [localidades, setLocalidades] = useState(['localidad1', 'localidad2', 'localidad3', 'localidad4']);
  
  const [municipios, setMunicipios] = useState(municipiosArray);

  const [colonias, setColonias] = useState(coloniasArray);

  const [semestres, setSemestres] = useState(['1', '2', '3', '4', '5', '6', '7', '8']);
  const [semestre, setSemestre] = useState('');

  //const [escuela, setEscuela] = useState('');

  //const [escuelas, setEscuelas] = useState([])
  const [open, setOpen] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const [errorNombre, setErrorNombre] = useState('');
  const [errorApellido, setErrorApellido] = useState('');
  const [errorApellidoM, setErrorApellidoM] = useState('');
  const [errorMatricula, setErrorMatricula] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorSexo, setErrorSexo] = useState('');
  const [errorGenero, setErrorGenero] = useState('');
  const [errorLocalidad, setErrorLocalidad] = useState('')

  //const [errorFechaN, setErrorFechaN] = useState('');
  //const [errorEscuela, setErrorEscuela] = useState('');

  const [loading, setLoading] = useState(false)

  
  const styles ={
    
    imglogocontainer:{
      width: '100vw',
      height: '35vh'
    },
    imglogo:{
      width: '100%',
      height: '100%'
    },
    formContainer: {
      backgroundColor: '#19B2D7',
      padding: '3%',
      color: 'white',
      marginTop: '-20px',
      borderRadius: '2%'
    },
    field: {
      // width: '200%',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    fieldP: {
      // width: '160%',
      // color: 'white',
      backgroundColor: 'white',
      borderRadius: '10px',
      border: 'none',
      borderColor: 'transparent'
      
    }

    
  }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    //Handler for submit
    const submitHandlerStudent = async () => {

      console.log("entra aqui")
      setLoading(true)
      let error = false;
    
    //Validation
    if(nombre.length < 2 ) {
        setErrorNombre('Nombre inválido')
        setGlobalError('Revisa el formulario, aún hay campos incorrectos')
        error = true;
    }
    if(apellido.length < 2 ) {
        setErrorApellido('Apellido inválido')
        setGlobalError('Revisa el formulario, aún hay campos incorrectos')
        error = true;
    }
    if(apellidoM.length < 2 ) {
        setErrorApellidoM('Apellido inválido')
        setGlobalError('Revisa el formulario, aún hay campos incorrectos')
        error = true;
    }
    if(!validateEmail(email)){
      setErrorEmail('Email incorrecto')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    
    if(matricula.length < 8) {
      setErrorMatricula('La matricula es de al menos 8 caracteres')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
      
    }
    if(genero === '') {
      setErrorGenero('Selecciona tu género')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
      
    }
   
    if(municipio === '0' ) {
      
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }if(colonia === '0' ) {
      
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    if(semestre === '0' ) {
      
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
    }
    
    /*
    if(escuela === "") {
      setErrorEscuela('Selecciona tu escuela')
      setGlobalError('Revisa el formulario, aún hay campos incorrectos')
      error = true;
      
    }
    */
    

    const dataUserInfo = {
        email,
        rol: userRol,
        nombre,
        matricula,
        municipio,
        colonia,
        semestre,
        surnamep: apellido,
        surnamem: apellidoM,
        sex: genero,
        idusuario:userId

        //escuela
    }
    
    if(error) {
        setOpen(true)
      } else {
        console.log(dataUserInfo)
        
        try {
          
          const user = await editUser(dataUserInfo);
          console.log(user);
  
          


          setGlobalError({ open: true, msg: user.data.msg, key: new Date(), status:user.data.status })
          setOpen(true)      

          setTimeout(()=> {
            navigate(`/depurar/${userRol}`)
           }, 1500);

        } catch (error) {
          console.log(error)
          setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
          setOpen(true)    
        }
        
      }

    setLoading(false)

    }
    //Handler for submit
    const submitHandlerUser = async () => {
      console.log("usuario")
      console.log(userRol)
      console.log(typeof userRol)
        setLoading(true)
        let error = false;
      
      //Validation
      if(nombre.length < 2 ) {
          setErrorNombre('Nombre inválido')
          setGlobalError('Revisa el formulario, aún hay campos incorrectos')
          error = true;
      }
      if(apellido.length < 2 ) {
          setErrorApellido('Apellido inválido')
          setGlobalError('Revisa el formulario, aún hay campos incorrectos')
          error = true;
      }
      if(apellidoM.length < 2 ) {
        setErrorApellidoM('Apellido inválido')
        setGlobalError('Revisa el formulario, aún hay campos incorrectos')
        error = true;
    }
      if(!validateEmail(email)){
        setErrorEmail('Email incorrecto')
        setGlobalError('Revisa el formulario, aún hay campos incorrectos')
        error = true;
      }
      
      
     
      
  
      const dataUserInfo = {
          email,
          rol: userRol,
          nombre: nombre,
          surnamep: apellido,
          surnamem: apellido,
          idusuario:userId
      }
      console.log(dataUserInfo)
      
      if(error) {
        setOpen(true)
      } else {
        console.log(dataUserInfo) 
        
        try {
          
          const user = await editUser(dataUserInfo);
          console.log(user);

          setGlobalError({ open: true, msg: user.data.msg, key: new Date(), status:user.data.status })
          setOpen(true)      

          setTimeout(()=> {
            navigate(`/depurar/${userRol}`)
           }, 1500);
  
          
        } catch (error) {
          console.log(error)
          setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
          setOpen(true) 
        }
        
      }
  
      setLoading(false)
  
      }


    // Handle for close
    const handleClose = () => {

        setOpen(false)

    };

    
    // Handle Nombre
    const handlenNombre = (e) => {

        setNombre(e.target.value)
        setErrorNombre('')

    }
    // Handle Apellido
    const handleApellido = (e) => {

        setApellido(e.target.value)
        setErrorApellido('')

    }

    const handleApellidoM = (e) => {

        setApellidoM(e.target.value)
        setErrorApellidoM('')

    }
    // Handle Email
    const handleEmail = (e) => {

        setEmail(e.target.value)
        setErrorEmail('')

    }
    //Handle Password
    const handlePassword = (e) => {

        setPassword((e.target.value))
        setErrorPassword('')

    }
    // Handle Matricula
    const handleMatricula = (e) => {

        setMatricula(e.target.value)
        setErrorMatricula('')

    }
    /*
    //Handle Escuela
    const handleEscuela = (e) => {

        setEscuela((e.target.value))
        setErrorEscuela('')

    }
    // Handle FechaN
    const handleFechaN = (e) => {

        setFechaN(e.target.value)
        setErrorFechaN('')

    }
    */

    //Handle Genero
    const handleGenero = (e) => {

        setGenero(e.target.value)
        setErrorGenero('')

    }
    //Handle Genero
    const handleLocalidad = (e) => {

      setLocalidad(e.target.value)
      setErrorLocalidad('')

    }
    const handleMunicipio  = (e) => {
      setMunCode(e.target.value.toLowerCase().substr(0, 3));
      setMunicipio(e.target.value);
      setColonia('0')

    }
    const handleColonia = (e) => {
      setColonia(e.target.value);
    }
    const handleSemestre = (e) => {
      setSemestre(e.target.value);
    }

    const handleCancel = ()=>{
      setGlobalError({ open: true, msg: "Operación cancelada", key: new Date(), status:"error" })
          setOpen(true)      

          setTimeout(()=> {
            navigate(`/depurar/${userRol}`)
           }, 1500);

    }
  /*
  const fetchSchools = async () => {

    const result = await getSchools()
    console.log(result)
    setEscuelas(result.data.data)

  }

  useEffect(() => {
    fetchSchools()
  }, [])

  */

  let coloniaCharged = ''
  const getUser = async() => {

    try {
        
        const user = await getUserById({id: userId})


        setNombre(user.data.data[0].nombre)
        setApellido(user.data.data[0].apellido_p)
        setApellidoM(user.data.data[0].apellido_m)
        setEmail(user.data.data[0].email)
        setGenero(user.data.data[0].sexo)
        setSemestre(user.data.data[0].semestre)
        setMunicipio(user.data.data[0].municipio)
        setMunCode(user.data.data[0].municipio.toLowerCase().substr(0,3))
        setMatricula(user.data.data[0].matricula)
        setColonia(user.data.data[0].colonia)


        

        

    } catch (error) {
        console.log(error)
        setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
          setOpen(true) 
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  

  return (
    <>
      {/* <div style={styles.imglogocontainer}>
      <img src={top} style={styles.imglogo}/>
    </div> */}
    <Grid container style={{height:"100vh", width:"100vw", margin: '0 auto'}} justifyContent="center"
    alignItems="center">


      {/** RIGHT CONTAINER */}
      
      <Grid  item container md={8}

          // direction="column"
          // fullWidth
          justifyContent="center"
          // alignItems="center" 
          // spacing={3}
          style={ styles.formContainer }
          >
        
        
        {/* <Grid item xs={12} align="center">
          <Typography style={{fontSize: isSm ? '18px' : '24px', fontWeight: 'bold', marginBottom: '10px'}} > REGÍSTRATE </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography style={{fontSize: isSm ? '14px' : '18px',  marginBottom: '20px'}} >Gracias por llegar hasta aquí. #ComparteLaCiudad es un proyecto social para saber sobre las actividades y lugares que como jóven frecuentas constantemente</Typography>
        </Grid> */}


        <Grid item container xs={12} spacing={2}>
          <Grid item container md={6} style={{color: '#000000', textAlign:"left", display: 'inline'}}>
          <Typography style={{fontSize: isSm ? '18px' : '24px', fontWeight: 'bold', margin: '0 auto', marginBottom: '20px'}} > Editar usuario </Typography>
            <img src={img2} alt="" width={'100%'} height={100}/>
          {/* <Typography style={{fontSize: isSm ? '14px' : '18px', fontWeight: 100 }} >
            <span style={{fontWeight: 'bold'}}>Tu eres la llave del cambio</span>
          <br />
          <br />
          En Vamos Primero buscamos asegurar un futuro sostenible y equitativo, a través de la educación de calidad, la salud y el bienestar, buscando reducir el número de muertes y lesiones en niñas, niños y jóvenes en hechos viales.
          <br />
          <br />
          Mejorando la calidad de vida en las ciudades, con escalamiento en soluciones urbanas y de movilidad.</Typography> */}
         
          
          </Grid>
          <Grid item container md={6} spacing={2}>
            <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
            id="outlined-required"
            label="Nombre"
            type={'text'}
            onChange={handlenNombre}
            value={nombre}
            //error={errorNombre!== ''}
            //error={errorNombre}
            helperText={errorNombre}
            // onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}

          />
            </Grid>

            <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
            id="outlined-required"
            label="Apellido"
            type={'text'}
            onChange={handleApellido}
            value={apellido}
            // error={errorEmail!== ''}
            helperText={errorApellido}
            // onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}

          />
            </Grid>

            <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
            id="outlined-required"
            label="Apellido Materno"
            type={'text'}
            onChange={handleApellidoM}
            value={apellidoM}
            // error={errorEmail!== ''}
            helperText={errorApellidoM}
            // onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}

          />
            </Grid>

            <Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
            id="outlined-required"
            label="Email"
            type={'email'}
            onChange={handleEmail}
            value={email}
            // error={errorEmail!== ''}
            helperText={errorEmail}
            // onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}

          />
            </Grid>

            

        {/* <Grid item xs={12} >
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <CssTextFieldP
            color='secondary'
            style={styles.fieldP}
            // fullWidth
            
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePassword}
            error={errorPassword!== ''}
            
            // helperText={errorPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
          <FormHelperText style={{color: '#d32f2f'}}>{errorPassword}</FormHelperText>
        </FormControl>
        </Grid> */}
        {/*<Grid item xs={12} >
          <CssTextField
            color='secondary'
            style={styles.fieldP}
            fullWidth
            required
            id="outlined-adornment-password"
            label="Contraseña"
            type='password'
            value={password}
            onChange={handlePassword}
            // error={errorPassword!== ''}
            helperText={errorPassword}
            // onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}
            
          />
        </Grid>*/}

        

       {
        userRol == 0 && (
            <>
            <Grid item xs={12}>
          <FormControl >
          <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="demo-radio-buttons-group-label">Género</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleGenero}
            value={genero}
            
          >
            <FormControlLabel value="femenino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label="Femenino" />
            <FormControlLabel value="masculino" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label="Masculino" />
            <FormControlLabel value="otro" control={<Radio sx={{color: 'white', '&.Mui-checked': {color: 'white'}}}/>} label="Otro" />
            {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
          </RadioGroup>
        </FormControl>
        </Grid>
                <Grid item xs={12} >
            <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
            required
              id="outlined-matricula"
              label="Matrícula"
              type="text"
              autoComplete="current-matricula"
              onChange={handleMatricula}
              value={matricula}
              // error={errorMatricula !== ''}
              helperText={errorMatricula}
            //   onKeyPress={(e) => e.key === "Enter" && userRol === 0 ? submitHandlerStudent() : submitHandlerUser()}
            />
                </Grid>
                <Grid item xs={12}>
                <Select
                    style={{backgroundColor: 'white', borderRadius: '10px'}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={semestre}
                    label="Semestre"
                    onChange={handleSemestre}
                >
                    <MenuItem value={'0'}>Selecciona tu semestre actual</MenuItem>
                    {
                        semestres.map((semestre, index) => (
                            <MenuItem key={index} value={semestre}>{semestre}</MenuItem>
                        ))
                    }
                </Select>
                </Grid>
                {/* <Grid item xs={12}>
                <Select
                    style={{backgroundColor: 'white', borderRadius: '10px'}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={localidad}
                    label="Localidad"
                    onChange={handleLocalidad}
                >
                    <MenuItem value={'0'}>Selecciona tu localidad</MenuItem>
                    {
                        localidades.map((localidad, index) => (
                            <MenuItem key={index} value={localidad}>{localidad}</MenuItem>
                        ))
                    }
                </Select>
                </Grid> */}
                <Grid item xs={12}>
                <Select
                    style={{backgroundColor: 'white', borderRadius: '10px'}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={municipio}
                    label="Municipio"
                    onChange={handleMunicipio}
                >
                    <MenuItem value={'0'}>Selecciona tu municipio</MenuItem>
                    {
                        municipios.map((mun, index) => (
                            <MenuItem key={index} value={mun.nombre}>{mun.nombre}</MenuItem>
                        ))
                    }
                </Select>
                </Grid>
                <Grid item xs={12}>
                <Select
                    style={{backgroundColor: 'white', borderRadius: '10px'}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={colonia}
                    label="Colonia"
                    onChange={handleColonia}
                >
                    <MenuItem value={'0'}>Selecciona tu colonia</MenuItem>
                    {
                        munCode && colonias[munCode].map((col, index) => (
                            <MenuItem key={index} value={col.nombre}>{col.nombre}</MenuItem>
                        ))
                    }
                </Select>
                </Grid>
            </>
        )
       }
        {
            /* <Grid item xs={12}>
        <FormLabel style={{color: 'rgba(12, 17, 31, 1)'}} id="cumpleanioos">Cumpleaños</FormLabel>
          <CssTextField
          color='secondary'
            style={styles.field}
            fullWidth
            id="date"
            label="Fecha de Nacimiento"
            type="date"
            // defaultValue="2017-05-24"
            // sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleFechaN}
            value={mes_anio_nacimiento}
            // error={errorFechaN !== ''}
            helperText={errorFechaN}
          />
        </Grid>*/
        }
        
        {/*
        <Grid item xs={12}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Escuela</InputLabel>
            <CssSelect
            style={{backgroundColor: 'white', borderRadius: '100px'}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={escuela}
              label="Escuela"
              onChange={handleEscuela}
              error={errorEscuela !== ''}
              // helperText={errorEscuela}
            >
            {
              escuelas.map((escuela, index) => (
                <MenuItem value={escuela.idescuela} key={index}>{escuela.nombre}</MenuItem>
              ))
            }
        </CssSelect>
        </FormControl>
        </Grid>
        */}
        
        <Grid item container xs={23} spacing={2}>

        <Grid item  
          justifyContent="center"
          textAlign={'center'}
        >
          <Button 
            variant="contained"
            onClick={handleCancel}
            style={{background: 'rgba(237, 25, 54, 1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto 20px', fontWeight: 'bold'}}
        >Cancelar</Button>
        </Grid>

        <Grid item  
          justifyContent="center"
          textAlign={'center'}
        >
          <Button 
            variant="contained"
            onClick={userRol === '0' ? submitHandlerStudent : submitHandlerUser}
            style={{background: 'rgba(12, 17, 31, 1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto 20px', fontWeight: 'bold'}}
        >Enviar</Button>
        </Grid>

        </Grid>
        
        
        {
        loading && (
          <Box sx={{ display: 'flex' }} style={{margin: '0 auto'}} >
            <CircularProgress />
          </Box>
        )
      }
          </Grid>
        </Grid>

      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        
      >
        <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
      </Snackbar>

    </Grid>
    </>
    
  )
}