import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API
import { userInfo } from '../api/services/userAPI'


// Initial State
const initialState = {
    id: null,
    rol: -1,
    matricula: '',
    status: null,
    email: null,
}


// Actions
export const getUserInfo = createAsyncThunk(
  'user/getuserinfo',
  async (dispatch, getState, ) => {
    const response = await userInfo();
    /*const response = {
        id: 1,
        rol: 1,
        matricula: '112i9231u'
    }*/
    //console.log("Reducer")
    //console.log(1, response.data.data)
    return {
      id: response.data.data.idusuario,
      email:response.data.data.email,
      rol: response.data.data.rol,
      matricula: response.data.data.matricula
    }
  }
)


// Reducer
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteInfo: (state) => {
      state.id = null
      state.rol = -1
      state.matricula = ''
      state.status = null
      state.email = null
    }
  },
  extraReducers: {
    [getUserInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.id = action.payload.id
      state.rol = action.payload.rol
      state.matricula = action.payload.matricula
      state.email = action.payload.email
      state.status = 'success'
    },
    [getUserInfo.rejected]: (state, action) => {
      state.status = 'failed'
    },
  }
})

export default userSlice.reducer
export const { deleteInfo } = userSlice.actions