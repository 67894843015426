import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, TextField, Button } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';



import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Swal from "sweetalert2";

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';

import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

//API
import { editTripService } from '../api/services/programmerAPI'


//Other components
import NavBar from "../components/NavbarContainer";
import { formatDate, formatTime } from "../helpers";
import { DateRange, Today } from "@mui/icons-material";

const weekDays = ["D", "L", "MA", "MI", "J", "V", "S"]
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

const viajes = [
    'Resumen_Colima_Cuauhtemoc_1400_27022023',
    'Resumen_Colima_Cuauhtemoc_1401_27022023',
    'Resumen_Colima_Cuauhtemoc_1402_27022023',
    'Resumen_Colima_Cuauhtemoc_1403_27022023',
    'Resumen_Colima_Cuauhtemoc_1404_27022023',
]
const lugares = [
    'Colima',
    'Cuahtemoc',
    'Insenco'
]



const CssTextField = styled(TextField)({
    // '& label': {
    //   color: 'transparent'
    // },
    borderRadius: '4px',
    border: '#C1C1C1 1px solid',
    '& .MuiFormHelperText-root': {
      color: 'red',
      paddingLeft: '2%',
      fontSize: '14px'
    },
    
    '& label.MuiInputLabel-shrink': {
      color: 'transparent',
    },
    
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  });


const EditTrip = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    
    const [idViaje, setIdViaje]=useState()
    const [lugarPartida, setLugarPartida] = useState('');
    const [lugarLlegada, setLugarLlegada] = useState('');
    const [horaPartida, setHoraPartida] = useState(formatTime(new Date()));
    const [horaLlegada, setHoraLlegada] = useState(formatTime(new Date()));
    const [asientos, setAsientos] = useState(0);
    const [placa, setPlaca] = useState('');
    const [numeroEconomico, setNumeroEconomico] = useState('');
    
    //const [rangeDate, setRangeDate] = useState([today, tomorrow]);
    const [rangeDate, setRangeDate] = useState();

    const [errorAsientos, setErrorAsientos] = useState('');
    const [errorPlaca, setErrorPlaca] = useState('');
    const [errorLP, setErrorLP] = useState('');
    const [errorLL, setErrorLL] = useState('');

    const [loading, setLoading] = useState(false);

    const [dias, setDias] = useState({
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false
    });
    
    const [openAlert, setOpenAlert] = useState(false);
    const [globalError, setGlobalError] = useState('');
    
    
    
    
    // ------------------ Handlers -------------
    const handleClose = () => {

        setOpenAlert(false)

    };
    const filterSelected = (array1, array2) => {

        let newArray = []
        for(let i = 0; i < array2.length; i++) {
            if(array2[i])  newArray.push(array1[i])
        }

        return newArray;
    }
    const handlerSubmit = async () => {
    
        let error = false;

        const diasSeleccionados = filterSelected(Object.keys(dias), Object.values(dias))
        
        setLoading(true)
    
        //Validation
        if(lugarPartida === '') {
            setErrorLP('Revisa el lugar de partida')
            setGlobalError('Lugar de partida invalido')
            error = true;
        }
        if(lugarLlegada === '') {
            setErrorLL('Revisa el lugar de llegada')
            setGlobalError('Lugar de llegada invalido')
            error = true;
        }
        if(horaLlegada === '') {
            setGlobalError('Hora de llegada invalida')
            error = true;
        }
        if(horaPartida=== '') {
            setGlobalError('Hora de partida invalida')
            error = true;
        }
        if(asientos < 1) {
            setGlobalError('Numero de asientos incorrecto')
            setErrorAsientos('Revisa la ocupación')
            error = true;
        }
        /*
        if(diasSeleccionados.length === 0) {
            
            error = true;
        }*/
        if(rangeDate.length === 0){
            setGlobalError('Dias seleccionados invalidos')
        }
        /*
        if(placa === '') {
            setGlobalError('Placa invalida')
            setErrorPlaca('Revisa la placa')
            error = true
            
        }*/
        
        /*
        const dateArray = rangeDate.map(fec =>{
            //console.log(fec)
            //console.log(fec.getDate())
            

              return fec.toLocaleString('en-GB', {
                timeZone: 'America/Mexico_City'
              }).replaceAll('/','-').split(",")[0];
              
          
            
        })
        */

        
        const dateArray = rangeDate[0].toLocaleString('en-GB', {
            timeZone: 'America/Mexico_City'
          }).split("T")[0];
          
        
    
        const data = {
            partida:lugarPartida,
            hora_partida:horaPartida,
            destino:lugarLlegada,
            hora_llegada:horaLlegada,
            personas_pasajeras:asientos,
            fechas: dateArray,
            placa,
            num_economico:numeroEconomico,
            idviaje:idViaje
    
        }
        
        if(error) {
            setOpenAlert(true)
        } else {
            console.log(data)

            
            try {
                const response = await editTripService(data)
                 console.log(response)
                 setGlobalError({ open: true, msg: response.data.msg, key: new Date(), status:response.data.status })
                setOpenAlert(true)
                //clear()
                
              } catch (err) {
                setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                setOpenAlert(true)
                console.log(err)
              }
              
            
            
          }
       // dispatch(deleteInfo())
    
       /* 
       try{
            const response = await login(dataUser)
            console.log(response)
            Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Bienvenido!',
            showConfirmButton: false,
            timer: 1500
            })
            return navigate(`/mis-lugares`)
            }
            catch(error){
            //setMessage({ open: true, msg: error.data.msg })
            setOpen(true)
            }
        
        */
            setLoading(false)
        
    
        
    
    }
    const handleChange = (event) => {
        setDias({
          ...dias,
          [event.target.name]: event.target.checked,
        });
    };
    
    const { lunes, martes, miercoles, jueves, viernes } = dias;

    const handleChangeLP = (e) => {
        setLugarPartida(e.target.value)
    }
    const handleChangeHP = (e) => {
        setHoraPartida(e.target.value)
    }
    const handleChangeHL = (e) => {
        setHoraLlegada(e.target.value)
    }
    const handleChangeLL = (e) => {
        setLugarLlegada(e.target.value)
    }
    const handleAsientos = (e) => {
        setAsientos(e.target.value)
        setErrorAsientos('')
    }
    const handlePlaca = (e) => {
        setPlaca(e.target.value)
        setErrorPlaca('')
    } 
    const handleNumeroEconomico = (e) => {
        setNumeroEconomico(e.target.value)
    }
    const handleDate = (e) => {
        
        setRangeDate(e[1])
    }

    const clear = () => {
        setLugarPartida('');
        setLugarLlegada('');
        setHoraPartida(formatTime(new Date()));
        setHoraLlegada(formatTime(new Date()));
        setAsientos(0);
        setPlaca('');
        setDias({
            lunes: false,
            martes: false,
            miercoles: false,
            jueves: false,
            viernes: false
        });

        setErrorAsientos('');
        setErrorPlaca('');
       
    }
    const handleCancel = () => {

        Swal.fire({
            title: '¿Estás seguro de cancelar?',
            text: "Una vez aceptado, se borrará la información agregada anteriormente",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Regresar',
            confirmButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clear()
            }
          })
    }


    //useEffects

    useEffect(()=>{
        
        
        if(state){
            
            //setMyViajes(oldArray => [...oldArray, state.trip])
            setLugarPartida(state.trip.partida)
            setHoraPartida(state.trip.hora_de_partida)
            setLugarLlegada(state.trip.destino)
            setHoraLlegada(state.trip.hora_de_llegada)
            setRangeDate([state.trip.fecha])
            setAsientos(state.trip.personas_pasajeras)
            setPlaca(state.trip.placa)
            setNumeroEconomico(state.trip.num_economico)
            setIdViaje(state.trip.idviaje)
        }else{
            navigate('/page2')
            
        }

        window.history.replaceState({}, document.title)
    },[])
    
    return (
        <>
            <NavBar/>
            
            <Grid container spacing={2} justifyContent="center" style={{padding: '20px'}}>
                <Grid item container xs={12} justifyContent="center">
                    <Grid item xs={7}>
                        <Typography>Lugar de partida:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <CssTextField
                            required
                            id="text-lp"
                            //label="Horario de partida"
                            type="text"
                            onChange={handleChangeLP}
                            value={lugarPartida}
                            //error={errorLP !== ''}
                            helperText={errorLP}
                            onKeyPress={(e) => e.key === "Enter" && handlerSubmit()}
                        />
                        {/*<Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={lugarPartida}
                            label="Lugar Partida"
                            onChange={handleChangeLP}
                        >
                            <MenuItem value={'0'}>Selecciona una opción</MenuItem>
                            {
                                lugares.map((lugar, index) => (
                                    <MenuItem key={index} value={lugar}>{lugar}</MenuItem>
                                ))
                            }
                        </Select>
                        */}
                    </Grid>
                </Grid>
                <Grid item container xs={7}>
                    <Grid item xs={12}>
                        <Typography>Horario de partida:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="time"
                            //label="Horario de partida"
                            pattern="[0-9]{2}:[0-9]{2}"
                            type="time"
                            onChange={handleChangeHP}
                            value={horaPartida}
                            //error={errorTime !== ''}
                            //helperText={errorTime}
                        />
                </Grid>
                </Grid>

                <Grid item container xs={12} justifyContent="center">
                    <Grid item xs={7}>
                        <Typography>Lugar de llegada:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <CssTextField
                            required
                            id="text-ll"

                            //label="Horario de partida"
                            type="text"
                            onChange={handleChangeLL}
                            value={lugarLlegada}
                            //error={errorLL !== ''}
                            helperText={errorLL}
                            onKeyPress={(e) => e.key === "Enter" && handlerSubmit()}
                        />
                        {/*<Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={lugarLlegada}
                            label="Lugar Partida"
                            onChange={handleChangeLL}
                        >
                            <MenuItem value={'0'}>Selecciona una opción</MenuItem>
                            {
                                lugares.map((lugar, index) => (
                                    <MenuItem key={index} value={lugar}>{lugar}</MenuItem>
                                ))
                            }
                        </Select>
                        */}
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <Grid item xs={7}>
                        <Typography>Horario de llegada:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            id="time"
                            //label="horario de llegada"
                            pattern="[0-9]{2}:[0-9]{2}"
                            type="time"
                            onChange={handleChangeHL}
                            value={horaLlegada}
                            //error={errorTime !== ''}
                            //helperText={errorTime}
                        />
                </Grid>
                </Grid>

                <Grid item container xs={7} justifyContent="start">
                    <Grid item xs={12}>
                        <Typography>Fechas del viaje:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            multiple={false}
                            value={rangeDate}
                            weekDays={weekDays}
                            months={months}
                            format="MMMM DD YYYY"
                            sort
                            plugins={[
                                <DatePanel />
                            ]}
                            onChange={handleDate}
                        />
                        {/*<DateRangePicker 
                            ranges={[]}
                            value={rangeDate}
                            onOk={handleDate}
                        />
                        */}
                        {/*<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={lunes} onChange={handleChange} name="lunes" />
                                }
                                label="Lunes"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={martes} onChange={handleChange} name="martes" />
                                }
                                label="Martes"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={miercoles} onChange={handleChange} name="miercoles" />
                                }
                                label="Miércoles"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={jueves} onChange={handleChange} name="jueves" />
                                }
                                label="Jueves"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={viernes} onChange={handleChange} name="viernes" />
                                }
                                label="Viernes"
                            />
                            </FormGroup>
                        </FormControl>
                        */}
                    </Grid>
                </Grid>
                
                <Grid item container xs={7}>
                    <Grid item xs={6}>
                        <Typography>Ocupación del vehículo:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <CssTextField
                        required
                        id="outlined-required"
                        label="Ocupación"
                        type='number'
                        onChange={handleAsientos}
                        value={asientos}
                        // error={errorEmail!== ''}
                        helperText={errorAsientos}
                        onKeyPress={(e) => e.key === "Enter" && handlerSubmit()}

                    />
                    </Grid>
                </Grid>
                <Grid item container xs={7}>
                    <Grid item xs={6}>
                        <Typography>Placa del vehículo:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <CssTextField
                        required
                        id="outlined-required"
                        //label="Placa"
                        type='text'
                        onChange={handlePlaca}
                        value={placa}
                        // error={errorEmail!== ''}
                        helperText={errorPlaca}
                        onKeyPress={(e) => e.key === "Enter" && handlerSubmit()}

                    />
                    </Grid>
                </Grid>
                <Grid item container xs={7}>
                    <Grid item xs={6}>
                        <Typography>Número económico:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <CssTextField
                        required
                        id="outlined-required1"
                        //label="Placa"
                        type='text'
                        onChange={handleNumeroEconomico}
                        value={numeroEconomico}
                        // error={errorEmail!== ''}
                        //helperText={errorNE}
                        onKeyPress={(e) => e.key === "Enter" && handlerSubmit()}

                    />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" style={{marginTop: 20}}>
                <Grid item xs={2}>
                    <Button variant="contained" color="success" onClick={handlerSubmit}>Guardar</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="error" onClick={handleCancel}>Cancelar</Button>
                </Grid>
            </Grid>
               
                
            </Grid>

            <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
                
            >
                <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
            </Snackbar>
        </>
    )

}

export default EditTrip;