import dataUsers from './data.json'

import axios, { instanceI as axiosImages } from './axiosConfig';

  

export const getUserByRol = async(data) =>{
  return await axios.post(`/admin/getusersbyrol`, data)
}
export const deleteUser = async(data) => {
    return await axios.post(`/admin/deleteuser`, data)
}
export const getUserById = async(data) =>{
    return await axios.post(`/admin/getusersbyid`, data)
}
export const editUser = async(data) =>{
    return await axios.post(`/admin/edituser`, data)
}