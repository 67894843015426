

import axios, { instanceI as axiosImages } from '../axiosConfig';

  


export const queryTrips = async() => {
  return await axios.get(`/operator/listoftrips`)
}

export const scanCode = async(data) => {
  return await axios.post(`/operator/scancode`,data)
}

export const startTrip = async(data) => {
  return await axios.post(`/operator/starttrip`,data)
}

export const endTrip = async(data) => {
  return await axios.post(`/operator/endtrip`,data)
}

