import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";



//Other components
import NavBar from "../components/NavbarContainer";



const viajes = [
    'Resumen_Colima_Cuauhtemoc_1400_27022023',
    'Resumen_Colima_Cuauhtemoc_1401_27022023',
    'Resumen_Colima_Cuauhtemoc_1402_27022023',
    'Resumen_Colima_Cuauhtemoc_1403_27022023',
    'Resumen_Colima_Cuauhtemoc_1404_27022023',
]


const Page3 = () => {


    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} style={{marginBottom: '20px', padding: '30px'}}>
                <Grid item xs={12}>
                    <Typography>Resumenes de viajes</Typography>
                </Grid>
                <Grid item>
                    {
                        viajes.map((viaje, index) => (
                            <Grid item xs={12} key={index}> 
                                <a  href="#">{viaje}</a>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </>
    )

}

export default Page3;