import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Grid, Typography, TextField, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



import Swal from "sweetalert2";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


//Other components
import NavBar from "../../components/NavbarContainer";
import ImprimirModal from "../../components/ImprimirModal";
import { formatDate, formatTime } from "../../helpers";

import ImprimirDialog from "../../components/ImprimirDialog";

//API

import { validatemMtricula, filterTrips, completeReservation } from '../../api/services/reservasAPI'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  
const viajesDisponibles = [
    'Colima_Cuauhtemoc_(04/11/2023T10:00)',
    'Colima_INSENCO_(04/11/2023T10:00)',
    'INSENCO_Colima_(04/11/2023T10:00)',
]
const rutas = [
    'Colima - Cuauhtémoc',
    'Cuauhtémoc - Colima'
]

const weekDays = ["D", "L", "MA", "MI", "J", "V", "S"]
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

const myViajes = [
    {
        id: 1,
        nombre: 'COLIMA - INSENCO',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },{
        id: 2,
        nombre: 'INSENCO - COLIMA',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },{
        id: 3,
        nombre: 'COLIMA - INSENCO',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },
    {
        id: 4,
        nombre: 'INSENCO - COLIMA',
        datos: '16/Mayio/2023-10:00',
        legend: 'ya lo puedes pagar!'
    },
]


const CssTextField = styled(TextField)(({ theme, mycolor }) => ({
    // '& label': {
    //   color: 'transparent'
    // },
    
    borderRadius: '4px',
    border: '#C1C1C1 1px solid',
    '& .MuiFormHelperText-root': {
      color: mycolor,
      paddingLeft: '2%',
      fontSize: '14px'
    },
    
    '& label.MuiInputLabel-shrink': {
      color: 'transparent',
    },
    
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  }));


  /*
const CssTextField = styled(TextField)({
    // '& label': {
    //   color: 'transparent'
    // },
    
    borderRadius: '4px',
    border: '#C1C1C1 1px solid',
    '& .MuiFormHelperText-root': {
      color: 'red',
      paddingLeft: '2%',
      fontSize: '14px'
    },
    
    '& label.MuiInputLabel-shrink': {
      color: 'transparent',
    },
    
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  });*/

function getStyles(name, personName, theme) {
    return {
    fontWeight:
        personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    };
}
  

const ReservaViaje = () => {

    const { state } = useLocation();

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const theme = useTheme();
    
    const [matricula, setMatricula] = useState('');
    //const [email, setEmail] = useState('');
    const [ruta, setRuta] = useState('0');
    const [viajes, setViajes] = useState([]);
    const [dia, setDia] = useState('');
    const [horario, setHorario] = useState('');
    const [loading, setLoading] = useState(false);
    const [rangeDate, setRangeDate] = useState([today, tomorrow]);


    //flags
    const [flagMatricula, setFlagMatricula] = useState(false)

    //Vars
    const [studentData, setStudentData] = useState({})
    const [retrievedTrips,setRetrievedTrips] = useState([])
    const [filteredTrips, setFilteredTrips] = useState([])
    const [addedTrips, setAddedTrips] = useState([])
    const [finalTrips, setFinalTrips] = useState([])
    const [finalTripsUpdate, setFinalTripsUpdate] = useState([])
    const [hashes, setHashes] = useState([])

    const [dias, setDias] = useState({
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false
    });
    const [horarios, setHorarios] = useState({
    col1: false,
    col2: false
    });

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [globalError, setGlobalError] = useState('');

    const [errorMatricula, setErrorMatricula] = useState({});


    // ------------------ Handlers -------------
    const handleOpen = () => setOpen(true);

    const handleClose = () => {

        setOpenAlert(false)

    };
    const filterSelected = (array1, array2) => {

        let newArray = []
        for(let i = 0; i < array2.length; i++) {
            if(array2[i])  newArray.push(array1[i])
        }

        return newArray;
    }
    const handleSubmit = async () => {
    
        setLoading(true)

        let error = false;

        const diasSeleccionados = filterSelected(Object.keys(dias), Object.values(dias))
        const horaiosSeleccionados = filterSelected(Object.keys(horarios), Object.values(horarios))
    
        //Validation
        if(matricula.length < 10){
            setErrorMatricula('Matricula incorrecta')
            setGlobalError('Revisa el formulario, aún hay campos incorrectos')
            error = true;
        }
        if(ruta === '0') {
            setGlobalError('Revisa el formulario, aún hay campos incorrectos')
            error = true;
        }
        if(diasSeleccionados.length === 0) {
            setGlobalError('Revisa el formulario, aún hay campos incorrectos')
            error = true;
        }
        if(horaiosSeleccionados.length === 0) {
            setGlobalError('Revisa el formulario, aún hay campos incorrectos')
            error = true;
        }

        
        
    
        const data = {
            matricula,
            ruta,
            dias: diasSeleccionados,
            horarios: horaiosSeleccionados
            

            
        }

        if(error) {
            setOpenAlert(true)
          } else {
            console.log(data)

            //Guardar boleto

            //Generar boletos
            
            
            
          }
    
       // dispatch(deleteInfo())
    
       /* 
       try{
            const response = await login(dataUser)
            console.log(response)
            Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Bienvenido!',
            showConfirmButton: false,
            timer: 1500
            })
            return navigate(`/mis-lugares`)
            }
            catch(error){
            //setMessage({ open: true, msg: error.data.msg })
            setOpen(true)
            }
        
        */
       
        setLoading(false)
        
        handleOpen()
        
    
    }
    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setViajes(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    /*const handleChange = (event) => {
        setDias({
            ...dias,
            [event.target.name]: event.target.checked,
        });
    };
    */
    const handleChange1 = (event) => {
        setHorarios({
            ...horarios,
            [event.target.name]: event.target.checked,
        });
    };
    const { lunes, martes, miercoles, jueves, viernes } = dias;
    const { col1, col2 } = horarios;

    const handleRuta = (e) => {
        setRuta(e.target.value)
    }
    const handleMatricula= (e) => {
        setMatricula(e.target.value);
        setErrorMatricula('');
        setFlagMatricula(false)

        //Reset all variables
        setStudentData({})
        setRetrievedTrips([])
        setFilteredTrips([])
        setAddedTrips([])
        setFinalTrips([])
        setFinalTripsUpdate([])

    }
    const handleDate = (e) => {
        setRangeDate(e)
    }

    const clear = () => {

        //reset flags 
        setFlagMatricula(false)
        setStudentData({})
        setRetrievedTrips([])
        setFilteredTrips([])
        setAddedTrips([])
        setFinalTrips([])
        setFinalTripsUpdate([])

        setMatricula('')
        setRuta('0')
        setDias({
            lunes: false,
            martes: false,
            miercoles: false,
            jueves: false,
            viernes: false
        })
        setHorarios({
            col1: false,
            col2: false
        })
        setErrorMatricula('')
       
    }

    const handleCancel = () => {

        Swal.fire({
            title: '¿Estás seguro de cancelar?',
            text: "Una vez aceptado, se borrará la información agregada anteriormente",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Regresar',
            confirmButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clear()
            }
          })
    }
    

    const checkMatricula = async()=>{
        if(matricula !== ''){
            const sentMat = {matricula:matricula}
            try{
                const response = await validatemMtricula(sentMat)
                
                if(response.data.data.trips.length>0){
                    setErrorMatricula({msg:"Matricula valida. Se encontraron viajes por pagar.",color:"green"})
                    setFlagMatricula(true)
                    
                    //add cheked attribute
                    const auxArray = response.data.data.trips.map((viaje) =>{
                        viaje.checked = true
                        return viaje
                    })

                    setRetrievedTrips(auxArray)
                    setFinalTripsUpdate(oldArray => [...oldArray, ...auxArray]);
                }else{
                    setErrorMatricula({msg:"Matricula valida.",color:"green"})
                    setFlagMatricula(true)
                }
                
                setStudentData(response.data.data.student)

            }catch(err){
                setOpenAlert(true)
                setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                setErrorMatricula({msg:err.data.msg,color:"red"})

            }

        } else{
            setErrorMatricula({msg:'Matricula vacía.',color:"red"})
        }
    }
    

    const checkFilters = async ()=>{

        console.log(retrievedTrips)

        if(flagMatricula===true){
            
            
            const fechasArray = rangeDate.map((fecha)=>{
                
                return fecha.toLocaleString('en-GB', {
                    timeZone: 'America/Mexico_City'
                  }).replaceAll('/','-').split(",")[0];
            })

            

            const sentFilters = {
                idusuario:studentData.idusuario,
                ruta:ruta,
                fechas: fechasArray
            }

            

            try{
                const response = await filterTrips(sentFilters)

                const auxArray = response.data.data.map((viaje) =>{
                    viaje.checked = true
                    return viaje
                })

                setFilteredTrips(auxArray)
                console.log(response.data)
            }catch(err){
                setOpenAlert(true)
                setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
            }

        }else{
            setErrorMatricula({msg:"Debes colocar una matricula valida",color:"red"})
        }
    }

    const addTripToSelection = (newtrip)=>{
        
        setAddedTrips(oldArray => [...oldArray, newtrip]);
        setFilteredTrips((current) => current.filter((viaje) => viaje.idviaje !== newtrip.idviaje));

        setFinalTrips(oldArray => [...oldArray, newtrip]);
    }

    const handleTripChekedRetrieved = (idviaje)=>{
        
        setRetrievedTrips(
            retrievedTrips.map((trip)=>{
                

                if(trip.idviaje===idviaje){
                    if(trip.checked===false){
                        setFinalTripsUpdate(oldArray => [...oldArray, trip])
                    }else{
                        setFinalTripsUpdate((current) => current.filter((viaje) => viaje.idviaje !== idviaje));
                    }
                    

                }

                return trip.idviaje===idviaje? {...trip, checked:!trip.checked} : trip
            })
        )

        
        

        console.log(finalTripsUpdate)
    }

    const handleTripChekedFiltered = (idviaje)=>{
       
        /*
        setAddedTrips(
            addedTrips.map((trip)=>{
                return trip.idviaje===idviaje? {...trip, checked:!trip.checked} : trip
            })
        )
        
        setFinalTrips((current) => current.filter((viaje) => viaje.checked !== true));
        console.log(retrievedTrips)*/

        setAddedTrips(
            addedTrips.map((trip)=>{
                

                if(trip.idviaje===idviaje){
                    if(trip.checked===false){
                        setFinalTrips(oldArray => [...oldArray, trip])
                    }else{
                        setFinalTrips((current) => current.filter((viaje) => viaje.idviaje !== idviaje));
                    }
                    

                }

                return trip.idviaje===idviaje? {...trip, checked:!trip.checked} : trip
            })
        )

    }

    const handleSentData = async()=>{

        
        

        if(flagMatricula === true){
            
            if(finalTrips.length===0 && finalTripsUpdate.length===0){
                setOpenAlert(true)
                setGlobalError({ open: true, msg: "No hay viajes seleccionados", key: new Date(), status:"error" })
            }else{
                const data = {
                    updateTrips:finalTripsUpdate,
                    insertTrips:finalTrips,
                    idusuario: studentData.idusuario,
                    email: studentData.email
                }
                console.log(data)
                //Aqui hay que llamar a la api
                try{
                    const sentResponse = await completeReservation(data)
                    console.log(sentResponse.data.data)
                    setHashes(sentResponse.data.data)
                    setOpen(true)
                }catch(err){
                    setOpenAlert(true)
                    setGlobalError({ open: true, msg: err.data.msg, key: new Date(), status:err.data.status })
                }
                


            }

        }else{
            setErrorMatricula({msg:"Debes colocar una matricula valida",color:"red"})
            setOpenAlert(true)
            setGlobalError({ open: true, msg: "Matricula invalida", key: new Date(), status:"error" })
        }


    }

    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} justifyContent="center" style={{padding: '20px'}}>
                <Grid item md={7}>
                    <Typography variant="h6">1. Ingresa la matricula del estudiante</Typography>
                </Grid>
                <Grid container item md={7}>
                    <Grid item md={7}>
                    <CssTextField
                        required
                        id="outlined-required"
                        label="Matricula"
                        type={'text'}
                        onChange={handleMatricula}
                        value={matricula}
                        // error={errorEmail!== ''}
                        helperText={errorMatricula.msg}
                        mycolor={errorMatricula.color}
                        onKeyPress={(e) => e.key === "Enter" && handleSubmit()}

                    />
                    </Grid>
                    <Grid item md={5}>
                        <Button variant="contained" color="primary" onClick={checkMatricula}>Verificar matricula</Button>
                    </Grid>

                </Grid>
                <Grid item md={7}>
                <Typography variant="h6">2. Selecciona tus viajes.</Typography>
                <Typography variant="subtitle2"> Si ya hay viajes puedes pasar al siguiente paso.</Typography>
                </Grid>
                <Grid
                    container
                    justifyContent={'center'}
                    item 
                    md={12}
                >
                    <Grid container justifyContent={'center'} item md={2}>
                        <Grid item md={12}>
                            Desde
                        </Grid>
                        <Grid item md={12}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={ruta}
                                label="Ruta"
                                onChange={handleRuta}
                            >
                                <MenuItem value={'0'}>Selecciona una opción</MenuItem>
                                {
                                    rutas.map((lugar, index) => (
                                        <MenuItem key={index} value={lugar}>{lugar}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>

                    </Grid>
                    
                    <Grid container item md={3}>
                        <Grid item md={12}>
                            Fechas
                        </Grid>
                        <Grid item md={12}>
                        <DatePicker
                            
                            value={rangeDate}
                            weekDays={weekDays}
                            months={months}
                            format="MMMM DD YYYY"
                            sort
                            plugins={[
                                <DatePanel />
                            ]}
                            onChange={handleDate}
                        />
                        </Grid>

                    </Grid>
                    
                    {/* 
                     <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Filtrar los viajes</FormLabel>
                        <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox checked={true} onChange={handleChange} name="gilad" />
                            }
                            label="Solo hoy"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox checked={false} onChange={handleChange} name="jason" />
                            }
                            label="Los de la semana"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox checked={true} onChange={handleChange} name="antoine" />
                            }
                            label="INSENCO-COLIMA"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox checked={false} onChange={handleChange} name="antoine" />
                            }
                            label="COLIMA-INSENCO"
                        />
                    </FormGroup>
                    </FormControl>
                    */}
                </Grid>
                <Grid container item md={12} justifyContent="flex-end">
                <Grid item md={3}>
                        <Button variant="contained" color="primary" onClick={checkFilters}>Buscar viajes</Button>
                    </Grid>
                </Grid>
                <Grid item md={7}>
                    {
                        filteredTrips.map(viaje => (
                            <Alert 
                                severity="warning" 
                                key={viaje.idviaje} 
                                style={{marginBottom: '15px', backgroundColor: '#F1F1F1'}}
                                action={
                                    <Button variant="contained" color="primary" onClick={() => addTripToSelection(viaje)}>Agregar viaje</Button>
                                  }
                            >
                                <AlertTitle>{viaje.partida} - {viaje.destino}</AlertTitle>
                                {viaje.fecha.split("T")[0]} a las {viaje.hora_de_partida} 
                            </Alert>
                        ))
                    }
                    
                </Grid>
                {/* 
                <Grid item md={7}>
                    <FormControl sx={{ width: 400 }}>
                        <InputLabel id="demo-multiple-chip-label">Tus viajes</InputLabel>
                        <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={viajes}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {viajesDisponibles.map((viajeDisponible, index) => (
                            <MenuItem
                            key={index}
                            value={viajeDisponible}
                            style={getStyles(viajeDisponible, viajesDisponibles, theme)}
                            >
                            {viajeDisponible}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ruta}
                        label="Ruta"
                        onChange={handleRuta}
                    >
                        <MenuItem value={'0'}>Selecciona una opción</MenuItem>
                        {
                            lugares.map((lugar, index) => (
                                <MenuItem key={index} value={lugar}>{lugar}</MenuItem>
                            ))
                        }
                    </Select>
                    
                </Grid>
                */}

                {/*<Grid item md={7}>
                    <Typography>3. Selecciona el día</Typography>
                </Grid>
                <Grid item md={7}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={lunes} onChange={handleChange} name="lunes" />
                                }
                                label="Lunes 27 de febrero"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={martes} onChange={handleChange} name="martes" />
                                }
                                label="Martes 28 de febrero"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={miercoles} onChange={handleChange} name="miercoles" />
                                }
                                label="Miercoles 1 marzo"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={jueves} onChange={handleChange} name="jueves" />
                                }
                                label="Jueves 2 de marzo"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={viernes} onChange={handleChange} name="viernes" />
                                }
                                label="Viernes 3 de Marzo"
                            />
                            </FormGroup>
                    </FormControl>
                </Grid>

                <Grid item md={7}>
                    <Typography>4. Selecciona los horarios</Typography>
                </Grid>
                <Grid item md={7}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={col1} onChange={handleChange1} name="col1" />
                                }
                                label="Col- Cuah, Lunes, salida 9:00, Llegada 10:00"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={col2} onChange={handleChange1} name="col2" />
                                }
                                label="Col- Cuah, Lunes, salida 13:00, Llegada 14:00"
                            />
                            
                            </FormGroup>
                    </FormControl>               
                </Grid>
                */}
                <Grid item md={7}>
                    <Typography variant="h6">3. Selecciona los viajes por pagar</Typography>
                </Grid>
                <Grid item md={7}>
                    {   

                        retrievedTrips.map(viaje => (
                            <Alert 
                                severity="warning" 
                                key={viaje.idviaje + 1000} 
                                style={{marginBottom: '15px', backgroundColor: '#E8DDFC'}}
                            >
                                <AlertTitle>{viaje.partida} - {viaje.destino}</AlertTitle>
                                <Checkbox checked={viaje.checked} onChange={()=>{handleTripChekedRetrieved(viaje.idviaje)}} />
                                {viaje.fecha.split("T")[0]} a las {viaje.hora_de_partida}  — <strong>agrega el viaje</strong>
                            </Alert>
                        ))
                    }
                    {
                        addedTrips.map(viaje => (
                            <Alert 
                                severity="warning" 
                                key={viaje.idviaje + 1000} 
                                style={{marginBottom: '15px', backgroundColor: '#E8DDFC'}}
                            >
                                <AlertTitle>{viaje.partida} - {viaje.destino}</AlertTitle>
                                <Checkbox checked={viaje.checked} onChange={()=>{handleTripChekedFiltered(viaje.idviaje)}} />
                                {viaje.fecha.split("T")[0]} a las {viaje.hora_de_partida}  — <strong>agrega el viaje</strong>
                            </Alert>
                        ))
                    }
                    
                </Grid>
                <Grid item md={7}>
                    <Typography>4. Total a pagar</Typography>
                </Grid>
                <Grid item md={7}>
                    <Typography>El costo de los viajes reservados es de: <span style={{fontWeight: 'bold', fontSize: '22px'}}>$ {5 * (finalTrips.length + finalTripsUpdate.length) }</span></Typography>
                </Grid>


                
                <Grid container spacing={2} justifyContent="center" style={{marginTop: 20}}>
                <Grid item md={2}>
                    <Button variant="contained" color="success" onClick={handleSentData}>Aceptar</Button>
                </Grid>
                <Grid item md={2}>
                    <Button variant="contained" color="error" onClick={handleCancel}>Cancelar</Button>
                </Grid>
            </Grid>
               
                
            </Grid>

            <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
                
            >
                <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
            </Snackbar>


            <ImprimirDialog 
                open={open}
                setOpen={setOpen}
                hashes={hashes}
            />
        </>
    )

}

export default ReservaViaje;