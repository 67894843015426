
const municipiosArray = [
    {
      clave: 'arm',
      nombre: 'Armería'
    },
    {
      clave: 'col',
      nombre: 'Colima'
    },	
    {
      clave: 'com',
      nombre: 'Comala'
    },	
    {
      clave: 'coq',
      nombre: 'Coquimatlán'
    },	
    {
      clave: 'cua',
      nombre: 'Cuauhtémoc'
    },
    {
      clave: 'ixt',
      nombre: 'Ixtlahuacán'
    },
    {
      clave: 'man',
      nombre: 'Manzanillo'
    },
    {
      clave: 'min',
      nombre: 'Minatitlán'
    },
    {
      clave: 'tec',
      nombre: 'Tecomán'
    },
    {
      clave: 'vil',
      nombre: 'Villa de Álvarez'
    },
    {
      clave: 'ton',
      nombre: 'Tonila'
    },
    
  ]


const coloniasArray = {
    arm: [
      {"clave": '754', "nombre": 'RINCON DEL DIABLO'} ,
      {"clave": '753', "nombre": 'LOS PINOS'} ,
      {"clave": '756', "nombre": 'PERIQUILLO'} ,
      {"clave": '755', "nombre": 'BUGAMBILIAS'} ,
      {"clave": '758', "nombre": 'ALLENDE'} ,
      {"clave": '757', "nombre": 'BENITO JUAREZ'} ,
      {"clave": '760', "nombre": 'COFRADIA DE JUAREZ'} ,
      {"clave": '759', "nombre": 'CONSTITUCION'} ,
      {"clave": '746', "nombre": 'BENITO JUAREZ'} ,
      {"clave": '748', "nombre": 'CUYUTLAN'} ,
      {"clave": '747', "nombre": 'NUEVA'} ,
      {"clave": '752', "nombre": 'FLOR DE COCO'} ,
      {"clave": '751', "nombre": 'TORRES QUINTERO'} ,
      {"clave": '690', "nombre": 'LA PALMERA'} ,
      {"clave": '692', "nombre": 'RINCON DE LOPEZ'} ,
      {"clave": '691', "nombre": 'LA LAGUNA'} ,
      {"clave": '321', "nombre": 'LOS REYES'} ,
      {"clave": '326', "nombre": 'AUGUSTO GOMEZ VILLANUEVA'} ,
      {"clave": '312', "nombre": 'LAS LOMAS'} ,
      {"clave": '262', "nombre": 'HACIENDA LAS PRIMAVERAS'} ,
      {"clave": '255', "nombre": 'VILLAS PARAISO'} ,
      {"clave": '226', "nombre": 'EL CAMPANARIO'} ,
      {"clave": '239', "nombre": 'PRIMAVERAS'} ,
      {"clave": '101', "nombre": 'BALNEARIO PARAISO'} ,
      {"clave": '107', "nombre": 'CENTRO'} ,
      {"clave": '90', "nombre": 'CRISTO REY'} ,
      {"clave": '66', "nombre": 'EL PELILLO'} ,
      {"clave": '65', "nombre": 'LAZARO CARDENAS'} ,
      {"clave": '68', "nombre": 'VALLE DEL SOL'} ,
      {"clave": '67', "nombre": 'GREGORIO TORRES QUINTERO'} ,
      {"clave": '70', "nombre": 'EJIDO ARMERIA'} ,
      {"clave": '69', "nombre": 'EL ARENAL'} ,
      {"clave": '72', "nombre": 'EMILIANO ZAPATA'} ,
      {"clave": '71', "nombre": 'INDEPENDENCIA'} ,
      {"clave": '74', "nombre": 'SAGRADO CORAZON'} ,
      {"clave": '73', "nombre": 'FERROCARRIL'} ,
      {"clave": '78', "nombre": 'LINDAVISTA'} ,
    ],
    col: [
      {"clave": '824', "nombre": 'BRISAS DEL SALTO'} ,
{"clave": '823', "nombre": 'TEPAMES'} ,
{"clave": '749', "nombre": 'RESIDENCIAL MONTEVISTA'} ,
{"clave": '709', "nombre": 'VIRREYES'} ,
{"clave": '711', "nombre": 'FRANCISCO ZARAGOZA VAZQUEZ'} ,
{"clave": '716', "nombre": 'LAS ENCINAS'} ,
{"clave": '702', "nombre": 'ESMERALDA'} ,
{"clave": '703', "nombre": 'PUERTA CAMINO REAL 2'} ,
{"clave": '660', "nombre": 'GUADALAJARITA'} ,
{"clave": '659', "nombre": 'PARQUE ROYAL'} ,
{"clave": '664', "nombre": 'EL ROBLE'} ,
{"clave": '663', "nombre": 'EL VOLANTIN'} ,
{"clave": '670', "nombre": 'PUERTA DEL VALLE'} ,
{"clave": '642', "nombre": 'LOS OLIVOS'} ,
{"clave": '641', "nombre": 'LA ARMONIA'} ,
{"clave": '644', "nombre": 'RESIDENCIAL SANTA GERTRUDIS'} ,
{"clave": '643', "nombre": 'RESIDENCIAL SANTA BARBARA'} ,
{"clave": '646', "nombre": 'RESIDENCIAL ESMERALDA NORTE'} ,
{"clave": '645', "nombre": 'PASEO DE LA CANTERA'} ,
{"clave": '648', "nombre": 'RESIDENCIAL ESMERALDA'} ,
{"clave": '647', "nombre": 'PASEOS DE LA HACIENDA'} ,
{"clave": '650', "nombre": 'EL CHANAL'} ,
{"clave": '649', "nombre": 'JARDINES DE LA HACIENDA'} ,
{"clave": '652', "nombre": 'RINCON DEL COLIBRI'} ,
{"clave": '651', "nombre": 'REAL SANTA BARBARA'} ,
{"clave": '654', "nombre": 'RESIDENCIAL VALLE VERDE'} ,
{"clave": '653', "nombre": 'RESIDENCIAL ROMANZA'} ,
{"clave": '656', "nombre": 'COLONIA GRANJAS CAMPESTRES DEL CHANAL'} ,
{"clave": '655', "nombre": 'RESIDENCIAL LA PRIMAVERA'} ,
{"clave": '634', "nombre": 'LOMAS VISTA HERMOSA'} ,
{"clave": '633', "nombre": 'MIGUEL HIDALGO'} ,
{"clave": '636', "nombre": 'LAS PALMAS'} ,
{"clave": '635', "nombre": 'LOS CIRUELOS'} ,
{"clave": '638', "nombre": 'PUERTA DEL SOL'} ,
{"clave": '637', "nombre": 'LOMAS VERDES'} ,
{"clave": '640', "nombre": 'LA HUERTA DE DON PEDRO'} ,
{"clave": '639', "nombre": 'COLINAS DE SANTA BARBARA'} ,
{"clave": '610', "nombre": 'REAL LA FLORESTA'} ,
{"clave": '609', "nombre": 'UNIDAD ANTORCHISTA'} ,
{"clave": '614', "nombre": 'EL TECOLOTE'} ,
{"clave": '615', "nombre": 'TIERRA Y LIBERTAD'} ,
{"clave": '621', "nombre": 'ANDARES DEL JAZMIN'} ,
{"clave": '604', "nombre": 'IKAL'} ,
{"clave": '592', "nombre": 'RIVERA DEL JAZMIN'} ,
{"clave": '545', "nombre": 'PABLO SILVA GARCIA'} ,
{"clave": '548', "nombre": 'JUANA DE ASBAJE'} ,
{"clave": '530', "nombre": 'PARAJES DEL SUR'} ,
{"clave": '529', "nombre": 'RESIDENCIAL SAN GERMAN'} ,
{"clave": '532', "nombre": 'REFORMA'} ,
{"clave": '531', "nombre": 'INFONAVIT PIMENTEL LLERENAS'} ,
{"clave": '534', "nombre": 'FRANCISCO VILLA'} ,
{"clave": '533', "nombre": 'BENITO JUAREZ'} ,
{"clave": '536', "nombre": 'SANTA ELENA'} ,
{"clave": '535', "nombre": 'MARGARITAS'} ,
{"clave": '541', "nombre": 'LA RIVERA'} ,
{"clave": '544', "nombre": 'LAS HACIENDAS'} ,
{"clave": '543', "nombre": 'SANTA GERTRUDIS'} ,
{"clave": '516', "nombre": 'SAN CARLOS'} ,
{"clave": '515', "nombre": 'VILLAS DEL SUR'} ,
{"clave": '518', "nombre": 'LA ESPERANZA'} ,
{"clave": '519', "nombre": 'PUERTA REAL'} ,
{"clave": '522', "nombre": 'LOS PINOS'} ,
{"clave": '521', "nombre": 'VILLA DE LOS PINOS'} ,
{"clave": '524', "nombre": 'EL MIRADOR DE COLIMA'} ,
{"clave": '523', "nombre": 'RINCONADA DE SAN PABLO'} ,
{"clave": '525', "nombre": 'DIAMANTES'} ,
{"clave": '528', "nombre": 'BOSQUES DEL SUR'} ,
{"clave": '498', "nombre": 'VILLA SAN SEBASTIAN'} ,
{"clave": '497', "nombre": 'INFONAVIT CAMINO REAL'} ,
{"clave": '500', "nombre": 'EL PORVENIR'} ,
{"clave": '499', "nombre": 'LAS VIBORAS'} ,
{"clave": '502', "nombre": 'FRANCISCO I MADERO'} ,
{"clave": '501', "nombre": 'LOMAS DE SANTA ELENA'} ,
{"clave": '504', "nombre": 'LAS FUENTES'} ,
{"clave": '503', "nombre": 'LOS VOLCANES'} ,
{"clave": '505', "nombre": 'GIRASOLES'} ,
{"clave": '508', "nombre": 'CENTRO'} ,
{"clave": '507', "nombre": 'LAS BUGAMBILIAS'} ,
{"clave": '510', "nombre": 'LOMA DE JUAREZ'} ,
{"clave": '511', "nombre": 'PISCILA'} ,
{"clave": '482', "nombre": 'ARBOLEDAS DE LA HACIENDA'} ,
{"clave": '481', "nombre": 'RINCONADA DEL PEREYRA'} ,
{"clave": '484', "nombre": 'VILLAS DEL BOSQUE'} ,
{"clave": '483', "nombre": 'LA ALBARRADA'} ,
{"clave": '486', "nombre": 'FATIMA'} ,
{"clave": '485', "nombre": 'PLACETAS ESTADIO'} ,
{"clave": '488', "nombre": 'MAGISTERIAL'} ,
{"clave": '487', "nombre": 'JARDINES DE LA CORREGIDORA'} ,
{"clave": '490', "nombre": 'NIÑOS HEROES'} ,
{"clave": '489', "nombre": 'HUERTAS DEL SOL'} ,
{"clave": '492', "nombre": 'LOMAS DE CIRCUNVALACION'} ,
{"clave": '491', "nombre": 'JARDINES RESIDENCIALES'} ,
{"clave": '494', "nombre": 'INFONAVIT LA ESTANCIA'} ,
{"clave": '493', "nombre": 'JARDINES DE LAS LOMAS'} ,
{"clave": '496', "nombre": 'FOVISSSTE'} ,
{"clave": '495', "nombre": 'CAMINO REAL'} ,
{"clave": '466', "nombre": 'MAGISTERIAL SNTE-6'} ,
{"clave": '465', "nombre": 'FORAS'} ,
{"clave": '468', "nombre": 'LOS VIVEROS'} ,
{"clave": '467', "nombre": 'PATIOS DEL FERROCARRIL'} ,
{"clave": '470', "nombre": 'RESIDENCIAL SAN PABLO'} ,
{"clave": '469', "nombre": 'EL MORALETE'} ,
{"clave": '472', "nombre": 'EL TIVOLI'} ,
{"clave": '471', "nombre": 'EL PERIODISTA'} ,
{"clave": '474', "nombre": 'LEONARDO B GUTIERREZ'} ,
{"clave": '473', "nombre": 'QUINTA EL TIVOLI'} ,
{"clave": '476', "nombre": 'MAGNOLIAS I FRACC ARBOLEDAS'} ,
{"clave": '475', "nombre": 'POPULAR'} ,
{"clave": '478', "nombre": 'GREGORIO TORRES QUINTERO'} ,
{"clave": '477', "nombre": 'VALLE PARAISO'} ,
{"clave": '480', "nombre": 'ARBOLEDAS'} ,
{"clave": '479', "nombre": 'INDECO ALBARRADA'} ,
{"clave": '454', "nombre": 'LAURELES'} ,
{"clave": '456', "nombre": 'JOSEFA ORTIZ DE DOMINGUEZ'} ,
{"clave": '455', "nombre": 'JARDINES DE LA ESTANCIA'} ,
{"clave": '458', "nombre": 'LAZARO CARDENAS'} ,
{"clave": '457', "nombre": 'REVOLUCION'} ,
{"clave": '460', "nombre": 'LAS TORRES'} ,
{"clave": '459', "nombre": 'SAN JOSE NORTE'} ,
{"clave": '462', "nombre": 'LOS PINOS'} ,
{"clave": '461', "nombre": 'LUIS DONALDO COLOSIO'} ,
{"clave": '464', "nombre": 'GREGORIO ANGUIANO'} ,
{"clave": '463', "nombre": 'COSTEÑO'} ,
{"clave": '407', "nombre": 'EL PRADO'} ,
{"clave": '412', "nombre": 'EL YAQUI'} ,
{"clave": '414', "nombre": 'LO DE VILLA'} ,
{"clave": '413', "nombre": 'VALLE REAL'} ,
{"clave": '415', "nombre": 'RESIDENCIAL PRADOS DEL SUR'} ,
{"clave": '398', "nombre": 'EJIDAL'} ,
{"clave": '400', "nombre": 'PARQUE INDUSTRIAL'} ,
{"clave": '399', "nombre": 'J JESUS RIOS'} ,
{"clave": '334', "nombre": 'TINAJAS'} ,
{"clave": '333', "nombre": 'ESTAPILLA'} ,
{"clave": '336', "nombre": 'CARDONA'} ,
{"clave": '914', "nombre": 'JAZMINES'} ,
{"clave": '913', "nombre": 'NUEVO MILENIO IV'} ,
{"clave": '916', "nombre": 'LA ESTANCIA'} ,
{"clave": '915', "nombre": 'NUEVO MILENIO'} ,
{"clave": '918', "nombre": 'SIGLO XXI'} ,
{"clave": '917', "nombre": 'MIRADOR DE LA CUMBRE'} ,
{"clave": '920', "nombre": 'PRADOS DE LA ESTANCIA'} ,
{"clave": '919', "nombre": 'GUSTAVO VAZQUEZ MONTES'} ,
{"clave": '898', "nombre": 'CUAUHTEMOC'} ,
{"clave": '897', "nombre": 'MOCTEZUMA'} ,
{"clave": '900', "nombre": 'JARDINES DEL SOL'} ,
{"clave": '899', "nombre": 'PARAISO'} ,
{"clave": '902', "nombre": 'INSURGENTES'} ,
{"clave": '901', "nombre": 'BUROCRATAS MUNICIPALES'} ,
{"clave": '904', "nombre": 'SAN RAFAEL'} ,
{"clave": '903', "nombre": 'VICENTE GUERRERO'} ,
{"clave": '906', "nombre": 'NUEVO PARAISO'} ,
{"clave": '905', "nombre": 'LOS ANGELES'} ,
{"clave": '908', "nombre": 'EL PEDREGAL'} ,
{"clave": '907', "nombre": 'CAMINO REAL'} ,
{"clave": '910', "nombre": 'LA VIRGENCITA'} ,
{"clave": '909', "nombre": 'LA GUADALUPE'} ,
{"clave": '912', "nombre": 'ORIENTAL'} ,
{"clave": '911', "nombre": 'DE LOS TRABAJADORES'} ,
{"clave": '882', "nombre": 'COLINAS DE SANTA FE'} ,
{"clave": '881', "nombre": 'JARDINES VICTORIA'} ,
{"clave": '884', "nombre": 'RESIDENCIAL VICTORIA'} ,
{"clave": '883', "nombre": 'LOMAS DEL VALLE'} ,
{"clave": '885', "nombre": 'PRIMAVERAS'} ,
{"clave": '892', "nombre": 'PUNTA NORTE'} ,
{"clave": '894', "nombre": 'IGNACIO ZARAGOZA'} ,
{"clave": '893', "nombre": 'HACIENDA EL BALCON'} ,
{"clave": '896', "nombre": 'SAN JOSE SUR'} ,
{"clave": '895', "nombre": 'SANTA AMALIA'} ,
{"clave": '870', "nombre": 'PUERTA PARAISO'} ,
{"clave": '869', "nombre": 'VALLE DORADO'} ,
{"clave": '872', "nombre": 'RESIDENCIAL SANTA MARIA'} ,
{"clave": '871', "nombre": 'RESIDENCIAL SAN JAVIER'} ,
{"clave": '874', "nombre": 'RESIDENCIAL LOREDO'} ,
{"clave": '873', "nombre": 'TERRALTA'} ,
{"clave": '876', "nombre": 'EL DIEZMO'} ,
{"clave": '875', "nombre": 'MORELOS'} ,
{"clave": '878', "nombre": 'JARDINES VISTA HERMOSA'} ,
{"clave": '877', "nombre": 'REAL VISTA HERMOSA'} ,
{"clave": '880', "nombre": 'LA CANTERA'} ,
{"clave": '879', "nombre": 'HACIENDA REAL'} ,
{"clave": '834', "nombre": 'LOS ASMOLES'} ,
{"clave": '833', "nombre": 'PUERTA DE ANZAR'} ,
{"clave": '835', "nombre": 'LAS GUASIMAS'} ,
{"clave": '838', "nombre": 'ALBATERRA'} ,
{"clave": '837', "nombre": 'ASTILLERO DE ABAJO'} ,
    ],
    com: [
      {"clave": '706', "nombre": 'CAMPESTRE SUCHITLAN'} ,
{"clave": '708', "nombre": 'LOMA ALTA'} ,
{"clave": '707', "nombre": 'SUCHITLAN'} ,
{"clave": '611', "nombre": 'COFRADIA DE SUCHITLAN'} ,
{"clave": '601', "nombre": 'REAL NOGUERAS'} ,
{"clave": '372', "nombre": 'CELSA VIRGEN DE DIAZ'} ,
{"clave": '371', "nombre": 'SOLIDARIDAD AGUAJITOS'} ,
{"clave": '374', "nombre": 'RAMON VELAZQUEZ'} ,
{"clave": '373', "nombre": 'LOMAS DE COMALA'} ,
{"clave": '376', "nombre": 'RESIDENCIAL EX HACIENDA NOGUERAS'} ,
{"clave": '375', "nombre": 'LA YERBABUENA'} ,
{"clave": '378', "nombre": 'REAL DE MENDOZA'} ,
{"clave": '377', "nombre": 'LOS LLANITOS'} ,
{"clave": '379', "nombre": 'LOMAS DEL PEDREGAL'} ,
{"clave": '354', "nombre": 'LA BARRANQUITA'} ,
{"clave": '355', "nombre": 'CUAUHTEMOC'} ,
{"clave": '358', "nombre": 'LAZARO CARDENAS'} ,
{"clave": '357', "nombre": 'CAMPESTRE COMALA'} ,
{"clave": '359', "nombre": 'FRANCISCO RAMIREZ VILLARREAL'} ,
{"clave": '364', "nombre": 'ZACUALPAN'} ,
{"clave": '365', "nombre": 'LA CAJA'} ,
{"clave": '342', "nombre": 'RESIDENCIAL LA HUERTA'} ,
{"clave": '341', "nombre": 'MIGUEL DE LA MADRID'} ,
{"clave": '344', "nombre": 'BARRIO ALTO'} ,
{"clave": '343', "nombre": 'LA TRINIDAD'} ,
{"clave": '346', "nombre": 'CAMPO VERDE'} ,
{"clave": '345', "nombre": 'LOS AGUAJES'} ,
{"clave": '348', "nombre": 'COMALA'} ,
{"clave": '347', "nombre": 'SANTA CECILIA'} ,
{"clave": '349', "nombre": 'LOMAS ALTAS'} ,
    ],
    coq: [
      {"clave": '828', "nombre": 'LA PRADERA'} ,
{"clave": '827', "nombre": 'SIN NOMBRE'} ,
{"clave": '790', "nombre": 'LUIS DONALDO COLOSIO'} ,
{"clave": '789', "nombre": 'SAN JUAN'} ,
{"clave": '792', "nombre": 'LAS FLORES'} ,
{"clave": '791', "nombre": 'PUEBLO JUAREZ'} ,
{"clave": '793', "nombre": 'ATARJEA VIEJA'} ,
{"clave": '718', "nombre": 'LAS TERESITAS'} ,
{"clave": '657', "nombre": 'COQUIMATLAN'} ,
{"clave": '406', "nombre": 'LOS LIMONES'} ,
{"clave": '386', "nombre": 'SAN MIGUEL'} ,
{"clave": '385', "nombre": 'CAZUMBA'} ,
{"clave": '388', "nombre": 'EL CHICAL'} ,
{"clave": '387', "nombre": 'JARDINES DEL LLANO'} ,
{"clave": '390', "nombre": 'SANTA RITA'} ,
{"clave": '389', "nombre": 'VALLE DE LAS HUERTAS'} ,
{"clave": '392', "nombre": 'LAS MORAS'} ,
{"clave": '391', "nombre": 'LOS CAMICHINES'} ,
{"clave": '380', "nombre": 'JALA'} ,
{"clave": '382', "nombre": 'VALLE VERDE'} ,
{"clave": '381', "nombre": 'LA ESPERANZA'} ,
{"clave": '384', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '383', "nombre": 'LAS HIGUERAS'} ,
{"clave": '327', "nombre": 'AGUA ZARCA'} ,
{"clave": '889', "nombre": 'ELIAS ZAMORA VERDUZCOELIAS ZAMORA VERDUZCO'} ,
    ],
    cua: [
      {"clave": '832', "nombre": 'GRANJA GRUPO INDUSTRIAL PROAVICOL'} ,
{"clave": '786', "nombre": 'QUESERIA'} ,
{"clave": '785', "nombre": 'LA HERMITA'} ,
{"clave": '788', "nombre": 'RINCONADA DEL CAÑAVERAL'} ,
{"clave": '787', "nombre": 'EL ARENAL'} ,
{"clave": '780', "nombre": 'ARSENIO FARELL CUBILLAS'} ,
{"clave": '782', "nombre": 'MA CONCEPCION BARBOSA DE ANGUIANO'} ,
{"clave": '781', "nombre": 'SANTA MARIANA'} ,
{"clave": '784', "nombre": 'MEXICO'} ,
{"clave": '783', "nombre": 'LA CRUZ'} ,
{"clave": '738', "nombre": 'EL GUAYABILLO'} ,
{"clave": '737', "nombre": 'LA ESCARAMUZA'} ,
{"clave": '740', "nombre": 'EL CAMPANARIO'} ,
{"clave": '739', "nombre": 'HUERTA ECOLOGICA DON NACHO'} ,
{"clave": '742', "nombre": 'LAGUNILLA'} ,
{"clave": '741', "nombre": 'EL TRAPICHE'} ,
{"clave": '722', "nombre": 'SAN JERONIMO'} ,
{"clave": '721', "nombre": 'LOS MONOS'} ,
{"clave": '724', "nombre": 'LA PRESA'} ,
{"clave": '723', "nombre": 'GRISELDA ALVAREZ'} ,
{"clave": '726', "nombre": 'LA MOJONERA'} ,
{"clave": '725', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '728', "nombre": 'CUAUHTEMOC'} ,
{"clave": '727', "nombre": 'VALLE REAL'} ,
{"clave": '730', "nombre": 'SAN RAFAEL'} ,
{"clave": '729', "nombre": 'LAS HIGUERAS'} ,
{"clave": '732', "nombre": 'LA ESCOBERA'} ,
{"clave": '731', "nombre": 'SAN GERONIMO'} ,
{"clave": '734', "nombre": 'LOMAS SANTA CRUZ'} ,
{"clave": '733', "nombre": 'RICARDO FLORES MAGON'} ,
{"clave": '736', "nombre": 'JARDINES DEL SUR'} ,
{"clave": '735', "nombre": 'LA CAYETANA'} ,
{"clave": '720', "nombre": 'COLINAS DEL CID'} ,
{"clave": '719', "nombre": 'EL TOREO'} ,
{"clave": '685', "nombre": 'REAL BUENAVISTA'} ,
{"clave": '370', "nombre": 'CHIAPA'} ,
{"clave": '369', "nombre": 'EL OCOTILLO'} ,
{"clave": '353', "nombre": 'SANTA ROSA'} ,
{"clave": '366', "nombre": 'ALCARACES'} ,
{"clave": '368', "nombre": 'PALMILLAS'} ,
{"clave": '338', "nombre": 'BUENAVISTA'} ,
{"clave": '337', "nombre": 'SAN JOAQUIN'} ,
{"clave": '340', "nombre": 'STA MARIA CLARA'} ,
{"clave": '339', "nombre": 'NUEVO SAN JOAQUIN'} ,
{"clave": '352', "nombre": 'EL CARIÑO'} ,
{"clave": '335', "nombre": 'ALZADA'} ,

    ],
    ixt: [
      {"clave": '829', "nombre": 'AQUILES SERDAN'} ,
{"clave": '750', "nombre": 'ZINACAMITLAN'} ,
{"clave": '689', "nombre": 'AGUA DE LA VIRGEN'} ,
{"clave": '678', "nombre": 'CARLOS DE LA MADRID VIRGEN'} ,
{"clave": '677', "nombre": 'DANIEL CONTRERAS LARA'} ,
{"clave": '680', "nombre": 'ONCE PUEBLOS'} ,
{"clave": '679', "nombre": '6 DE ENERO'} ,
{"clave": '682', "nombre": 'CENTRO'} ,
{"clave": '681', "nombre": 'LOS GOBERNADORES'} ,
{"clave": '684', "nombre": 'EL MOJOTAL'} ,
{"clave": '683', "nombre": 'LLANOS DE SAN GABRIEL'} ,
{"clave": '662', "nombre": 'LAS CONCHAS'} ,
{"clave": '14', "nombre": 'LA PRESA O BARRANCA DEL REBOZO'} ,
{"clave": '890', "nombre": 'JILIOTUPA'} ,
    ],
    man: [
      {"clave": '817', "nombre": 'LLANO DE LA MARINA'} ,
{"clave": '826', "nombre": 'ARBOL DE VIDA'} ,
{"clave": '825', "nombre": 'RESIDENCIAL BELONA'} ,
{"clave": '802', "nombre": 'SAN CARLOS'} ,
{"clave": '801', "nombre": 'LOMA ALTA'} ,
{"clave": '804', "nombre": 'TAPEIXTLES'} ,
{"clave": '803', "nombre": 'INDECO'} ,
{"clave": '806', "nombre": 'FSTSE'} ,
{"clave": '805', "nombre": 'ALBATROS'} ,
{"clave": '808', "nombre": 'PATIO DE CONTENEDORES AMOSA'} ,
{"clave": '807', "nombre": 'ARCOIRIS'} ,
{"clave": '810', "nombre": 'FONDEPORT'} ,
{"clave": '809', "nombre": 'TAPEIXTLES'} ,
{"clave": '812', "nombre": 'REAL DEL VALLE'} ,
{"clave": '811', "nombre": 'PUERTA DEL MAR'} ,
{"clave": '814', "nombre": 'OCEANO'} ,
{"clave": '813', "nombre": 'LAS GAVIOTAS RESIDENCIAL'} ,
{"clave": '816', "nombre": 'CAMOTLAN DE MIRAFLORES'} ,
{"clave": '815', "nombre": 1} ,
{"clave": '774', "nombre": 'VILLA FLORIDA'} ,
{"clave": '776', "nombre": 'CAMPOS-ROCIO'} ,
{"clave": '775', "nombre": 'AQUARIUM'} ,
{"clave": '778', "nombre": 'CAMPOS'} ,
{"clave": '777', "nombre": 'PUNTA DE CAMPOS'} ,
{"clave": '779', "nombre": 'LAS DUNAS'} ,
{"clave": '762', "nombre": 'EL CHARCO'} ,
{"clave": '761', "nombre": 'SOLEARES'} ,
{"clave": '764', "nombre": 'LOS ESPINOZA'} ,
{"clave": '763', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '766', "nombre": 'LA CIENEGA'} ,
{"clave": '765', "nombre": 'SAN CARLOS'} ,
{"clave": '768', "nombre": 'JALIPA'} ,
{"clave": '767', "nombre": 'LOS MORALES'} ,
{"clave": '744', "nombre": 'LAS CEIBAS RESIDENCIAL (EL CUOYA)'} ,
{"clave": '743', "nombre": 'LAS CEIBAS RESIDENCIAL'} ,
{"clave": '745', "nombre": 'LAS CEIBAS RESIDENCIAL (EL COPAL)'} ,
{"clave": '674', "nombre": 'VIDAIRE'} ,
{"clave": '676', "nombre": 'TERRA MITICA'} ,
{"clave": '675', "nombre": 'RESIDENCIAL DIAMANTE'} ,
{"clave": '688', "nombre": 'LAJAS Y TEPEHUAJES'} ,
{"clave": '687', "nombre": 'RESIDENCIAL CATLEYAS'} ,
{"clave": '666', "nombre": 'MANUEL AVILA CAMACHO'} ,
{"clave": '665', "nombre": 'EL HUIZCOLOTE'} ,
{"clave": '667', "nombre": 'CANOAS'} ,
{"clave": '672', "nombre": 'TAMARINDO'} ,
{"clave": '671', "nombre": 'REAL LA RIVERA'} ,
{"clave": '625', "nombre": 'NUEVO SALAGUA'} ,
{"clave": '628', "nombre": 'POCHOTE'} ,
{"clave": '629', "nombre": 'JARDINES SALAGUA'} ,
{"clave": '631', "nombre": 'DEL BOSQUE'} ,
{"clave": '612', "nombre": 'BAHIA AZUL'} ,
{"clave": '619', "nombre": 'LA TAMARINDERA'} ,
{"clave": '595', "nombre": 'LA TAMARINDERA'} ,
{"clave": '597', "nombre": 'AMPLIACION EL PEDREGAL'} ,
{"clave": '603', "nombre": 'RESIDENCIAL MAR DE PLATA'} ,
{"clave": '591', "nombre": 'VELADERO DE CAMOTLAN'} ,
{"clave": '306', "nombre": 'FRANCISCO VILLA'} ,
{"clave": '305', "nombre": 'LAS CAMELINAS'} ,
{"clave": '308', "nombre": 'PASEO DEL MAR'} ,
{"clave": '310', "nombre": 'PUERTA DEL SOL'} ,
{"clave": '309', "nombre": 'MONTEBELLO'} ,
{"clave": '311', "nombre": 'SOL DEL PACIFICO'} ,
{"clave": '314', "nombre": 'CIMA DEL PROGRESO'} ,
{"clave": '313', "nombre": 'LAS PERLAS I'} ,
{"clave": '315', "nombre": 'LA LUPITA'} ,
{"clave": '318', "nombre": 'VIVEROS'} ,
{"clave": '317', "nombre": 'LOMAS RESIDENCIAL'} ,
{"clave": '319', "nombre": 'FRANCISCO VILLA'} ,
{"clave": '290', "nombre": 'PUNTA ARENA'} ,
{"clave": '289', "nombre": 'LAS HUERTAS'} ,
{"clave": '294', "nombre": 'LA CALMA'} ,
{"clave": '293', "nombre": 'ALMENDROS RESIDENCIAL III'} ,
{"clave": '296', "nombre": 'ROSA MORADA'} ,
{"clave": '295', "nombre": 'II'} ,
{"clave": '298', "nombre": 'DEL MAR'} ,
{"clave": '297', "nombre": '20 DE NOVIEMBRE'} ,
{"clave": '300', "nombre": 'NATURA PACIFICO'} ,
{"clave": '302', "nombre": 'LAS PALMAS'} ,
{"clave": '301', "nombre": 'FRAILES DEL MAR'} ,
{"clave": '304', "nombre": 'CANTALEGRE'} ,
{"clave": '303', "nombre": 'LOS CIRUELOS'} ,
{"clave": '274', "nombre": 'MARIMAR I'} ,
{"clave": '273', "nombre": 'EL MIRADOR'} ,
{"clave": '276', "nombre": 'CLUB SANTIAGO'} ,
{"clave": '275', "nombre": 'SAN ISIDRO LABRADOR'} ,
{"clave": '278', "nombre": 'ARBOLEDAS'} ,
{"clave": '280', "nombre": 'V'} ,
{"clave": '279', "nombre": 'EL DORADO'} ,
{"clave": '282', "nombre": 'III'} ,
{"clave": '281', "nombre": 'IV'} ,
{"clave": '284', "nombre": 'TABACHINES'} ,
{"clave": '283', "nombre": 'LOMAS DEL MAR'} ,
{"clave": '286', "nombre": 'BUGAMBILIAS'} ,
{"clave": '285', "nombre": 'LAS PERLAS II'} ,
{"clave": '288', "nombre": 'PLAYA ESMERALDA'} ,
{"clave": '287', "nombre": 'NUEVA ESPERANZA'} ,
{"clave": '258', "nombre": 'LAZARO CARDENAS'} ,
{"clave": '257', "nombre": 'HACIENDA SANTA CLARA'} ,
{"clave": '260', "nombre": 'REAL DE SANTIAGO'} ,
{"clave": '259', "nombre": 'EL CAMPANARIO'} ,
{"clave": '261', "nombre": 'LA PEDREGOZA'} ,
{"clave": '264', "nombre": 'LA PLAYITA'} ,
{"clave": '263', "nombre": 'RESIDENCIAL LAS HIGUERAS'} ,
{"clave": '265', "nombre": 'SANTA CAROLINA'} ,
{"clave": '268', "nombre": 'COLOMOS RESIDENCIAL'} ,
{"clave": '267', "nombre": 'SAN FRANCISCO'} ,
{"clave": '269', "nombre": 'VILLAS DEL PACIFICO'} ,
{"clave": '272', "nombre": 'RESIDENCIAL PUNTA DORADA'} ,
{"clave": '271', "nombre": 'LA CEIBA'} ,
{"clave": '242', "nombre": 'PALMA DE MALLORCA'} ,
{"clave": '241', "nombre": 'MARIMAR II'} ,
{"clave": '244', "nombre": 'VILLAS CORAL'} ,
{"clave": '243', "nombre": 'PUERTA DE HIERRO'} ,
{"clave": '245', "nombre": 'AGUAZUL'} ,
{"clave": '256', "nombre": 'LOPEZ DE LEGAZPI'} ,
{"clave": '230', "nombre": 'ALMENDROS RESIDENCIAL II'} ,
{"clave": '229', "nombre": 'AGUA MARINA'} ,
{"clave": '232', "nombre": 'MARIMAR III'} ,
{"clave": '231', "nombre": 'ELIAS ZAMORA VERDUZCO'} ,
{"clave": '233', "nombre": 'SANTA RITA'} ,
{"clave": '236', "nombre": 'RESIDENCIAL AZUL MARINO DIAMANTE'} ,
{"clave": '235', "nombre": 'AZUL MARINO'} ,
{"clave": '238', "nombre": 'BUGAMBILIAS'} ,
{"clave": '237', "nombre": 'CAMPO DEPORTIVO'} ,
{"clave": '240', "nombre": 'EL GARZERO'} ,
{"clave": '212', "nombre": 'LOS MANGOS'} ,
{"clave": '215', "nombre": 'GUADALUPE VICTORIA'} ,
{"clave": '222', "nombre": 'FOVISSSTE'} ,
{"clave": '221', "nombre": 'INFONAVIT BRISAS DEL MAR'} ,
{"clave": '224', "nombre": 'MIRAMAR'} ,
{"clave": '223', "nombre": 'RESIDENCIAL PUERTA DEL MAR'} ,
{"clave": '194', "nombre": 'GAVIOTAS'} ,
{"clave": '193', "nombre": 'TUCANES'} ,
{"clave": '195', "nombre": 'GUACAMAYAS'} ,
{"clave": '198', "nombre": 'VILLAS CORALES'} ,
{"clave": '200', "nombre": 'O SERGIO MENDEZ ARCEO'} ,
{"clave": '203', "nombre": 'LAS PALMAS'} ,
{"clave": '206', "nombre": 'ARBOLEDAS'} ,
{"clave": '208', "nombre": 'BARRIO NUEVO'} ,
{"clave": '207', "nombre": 'DELFINES'} ,
{"clave": '177', "nombre": 'LA PUNTA'} ,
{"clave": '182', "nombre": 'OLAS ALTAS'} ,
{"clave": '185', "nombre": 'LA QUERENCIA'} ,
{"clave": '190', "nombre": 'AEROMEXICO'} ,
{"clave": '192', "nombre": 'GAVIOTAS RESIDENCIAL'} ,
{"clave": '191', "nombre": 'DEL MAR'} ,
{"clave": '167', "nombre": 'HERMOSA PROVINCIA'} ,
{"clave": '170', "nombre": 'PALMA REAL'} ,
{"clave": '174', "nombre": 'PENINSULA DE SANTIAGO'} ,
{"clave": '176', "nombre": 'LAS HADAS'} ,
{"clave": '146', "nombre": 'DEPORTIVA'} ,
{"clave": '145', "nombre": 'MIGUEL DE LA MADRID'} ,
{"clave": '148', "nombre": 'LOMAS VERDES'} ,
{"clave": '147', "nombre": 'LA JOYA'} ,
{"clave": '149', "nombre": 'VALLE DORADO'} ,
{"clave": '151', "nombre": 'JARDINES DE SANTIAGO'} ,
{"clave": '153', "nombre": 'VALLE ALTO'} ,
{"clave": '156', "nombre": 'RIO COLORADO'} ,
{"clave": '155', "nombre": 'OBRERA'} ,
{"clave": '130', "nombre": 'PORFIRIO GAYTAN NUÑEZ'} ,
{"clave": '129', "nombre": 'OBRADORES'} ,
{"clave": '132', "nombre": 'PERLAS DE OCCIDENTE'} ,
{"clave": '131', "nombre": 'BARRIO NUEVO'} ,
{"clave": '134', "nombre": 'COLINAS DE SANTIAGO'} ,
{"clave": '133', "nombre": 'EL JABALI'} ,
{"clave": '136', "nombre": 'ARTURO NORIEGA PIZANO'} ,
{"clave": '135', "nombre": 'ABELARDO L RODRIGUEZ'} ,
{"clave": '138', "nombre": 'SAN MARTIN'} ,
{"clave": '137', "nombre": '5 DE MAYO'} ,
{"clave": '140', "nombre": 'LOPEZ MATEOS'} ,
{"clave": '139', "nombre": 'LUISA MARIA CAMPEROS'} ,
{"clave": '142', "nombre": 'EL PARAISO'} ,
{"clave": '141', "nombre": 'LAS JOYAS'} ,
{"clave": '144', "nombre": 'LAZARO CARDENAS'} ,
{"clave": '143', "nombre": 'EL CERRITO'} ,
{"clave": '114', "nombre": 'LA CULEBRA'} ,
{"clave": '113', "nombre": 'LAS JUNTAS DE ABAJO'} ,
{"clave": '116', "nombre": 'EL CHAVARIN'} ,
{"clave": '115', "nombre": 'LA CENTRAL'} ,
{"clave": '118', "nombre": 'LAS BUGAMBILIAS'} ,
{"clave": '117', "nombre": 'EL NARANJO'} ,
{"clave": '120', "nombre": 'LA JOYA'} ,
{"clave": '119', "nombre": 'JAGUEY O RIO MARABASCO'} ,
{"clave": '122', "nombre": 'NUEVO MIRAMAR'} ,
{"clave": '121', "nombre": 'CLUB MAEVA'} ,
{"clave": '124', "nombre": 'REAL DEL COUNTRY'} ,
{"clave": '123', "nombre": 'LA ESPERANZA'} ,
{"clave": '126', "nombre": 'LAS FLORES'} ,
{"clave": '125', "nombre": 'MARAVILLAS DEL CAMPO'} ,
{"clave": '128', "nombre": 'LA CRUZ'} ,
{"clave": '127', "nombre": 'PEDRO NUÑEZ'} ,
{"clave": '98', "nombre": 'SAN BUENAVENTURA'} ,
{"clave": '97', "nombre": 'NUEVA'} ,
{"clave": '100', "nombre": 'LOS CEDROS'} ,
{"clave": '102', "nombre": 'SANTIAGO'} ,
{"clave": '104', "nombre": 'LOS CORALES'} ,
{"clave": '103', "nombre": 'MARINA NACIONAL'} ,
{"clave": '105', "nombre": 'VIVEROS PELAYO'} ,
{"clave": '108', "nombre": 'PUNTA DE AGUA DE CHANDIABLO'} ,
{"clave": '112', "nombre": 'NUEVO CUYUTLAN'} ,
{"clave": '111', "nombre": 'VENUSTIANO CARRANZA'} ,
{"clave": '82', "nombre": 'PARAISO SALAGUA'} ,
{"clave": '81', "nombre": 'BRISAS DE SALAGUA'} ,
{"clave": '84', "nombre": 'SANTA SOFIA'} ,
{"clave": '83', "nombre": 'VILLA LOS FLAMENCOS'} ,
{"clave": '86', "nombre": 'RESIDENCIAL ALMENDROS II'} ,
{"clave": '85', "nombre": 'MARBELLA'} ,
{"clave": '88', "nombre": 'CANTAMAR'} ,
{"clave": '87', "nombre": 'VALLE DEL CORAL'} ,
{"clave": '89', "nombre": 'PRIMAVERAS'} ,
{"clave": '92', "nombre": 'EL COLOMO'} ,
{"clave": '91', "nombre": 'LAS BRISAS'} ,
{"clave": '94', "nombre": 'VISTA DEL MAR'} ,
{"clave": '93', "nombre": 'LIBRAMIENTO'} ,
{"clave": '96', "nombre": 'SALAGUA'} ,
{"clave": '95', "nombre": 'RESIDENCIAL MANZANILLO COUNTRY CLUB'} ,
{"clave": '76', "nombre": 'CENTRO'} ,
{"clave": '75', "nombre": 4} ,
{"clave": '77', "nombre": 'PUNTA CHICA'} ,
{"clave": '80', "nombre": 'VALLE ESMERALDA'} ,
{"clave": '79', "nombre": 'LOMAS DE SANTIAGO'} ,
{"clave": '50', "nombre": 'LA NEGRITA'} ,
{"clave": '49', "nombre": 'BUGAMBILIAS'} ,
{"clave": '52', "nombre": 'PLAYA BLANCA'} ,
{"clave": '51', "nombre": '23 DE NOVIEMBRE'} ,
{"clave": '54', "nombre": 'LA ESPERANZA'} ,
{"clave": '53', "nombre": 'VILLA MAR'} ,
{"clave": '56', "nombre": 'PLAYA AZUL'} ,
{"clave": '55', "nombre": 'PLAYA REAL'} ,
{"clave": '58', "nombre": 'VILLA MAR'} ,
{"clave": '57', "nombre": 'PLAYA DE ORO'} ,
{"clave": '60', "nombre": 'ISLA REAL'} ,
{"clave": '59', "nombre": 'VALLE DORADO'} ,
{"clave": '62', "nombre": 'DEL SOL'} ,
{"clave": '61', "nombre": 'LOMAS DEL VALLE'} ,
{"clave": '64', "nombre": 'VILLA DEL MAR RESIDENCIAL'} ,
{"clave": '63', "nombre": 'PALMA REAL'} ,
{"clave": '34', "nombre": 7} ,
{"clave": '33', "nombre": 'SAN PEDRITO'} ,
{"clave": '36', "nombre": 6} ,
{"clave": '35', "nombre": 'BUROCRATA'} ,
{"clave": '38', "nombre": 'LIBERTAD'} ,
{"clave": '37', "nombre": 5} ,
{"clave": '40', "nombre": 'CUAUHTEMOC'} ,
{"clave": '39', "nombre": 'CONSTITUCION'} ,
{"clave": '42', "nombre": 'BONANZA'} ,
{"clave": '41', "nombre": 'ALAMEDA'} ,
{"clave": '44', "nombre": 3} ,
{"clave": '43', "nombre": 'PADRE HIDALGO'} ,
{"clave": '46', "nombre": 1} ,
{"clave": '45', "nombre": 2} ,
{"clave": '48', "nombre": 'LOMAS DE GREGORIO FIGUEROA'} ,
{"clave": '47', "nombre": 'VI REGION NAVAL MIL'} ,
{"clave": '18', "nombre": 'MORELOS'} ,
{"clave": '17', "nombre": 'DEL PACIFICO'} ,
{"clave": '20', "nombre": 'BELLAVISTA'} ,
{"clave": '19', "nombre": 'VI REGION NAVAL'} ,
{"clave": '22', "nombre": 'LEANDRO VALLE'} ,
{"clave": '21', "nombre": 'IGNACIO ZARAGOZA'} ,
{"clave": '24', "nombre": 'PUNTA GRANDE'} ,
{"clave": '23', "nombre": 'LAS TORRES'} ,
{"clave": '26', "nombre": 'LA FLECHITA'} ,
{"clave": '25', "nombre": 'RANCHO ALEGRE'} ,
{"clave": '28', "nombre": 'BENITO JUAREZ'} ,
{"clave": '27', "nombre": '16 DE SEPTIEMBRE'} ,
{"clave": '30', "nombre": 'EL ROCIO'} ,
{"clave": '29', "nombre": 'LAS JOYAS'} ,
{"clave": '32', "nombre": 8} ,
{"clave": '31', "nombre": 9} ,
{"clave": '16', "nombre": 'PLAYA AZUL'} ,
{"clave": '15', "nombre": 'SALAGUA'} ,
{"clave": '891', "nombre": 'REVOLUCION'} ,
{"clave": '866', "nombre": 'MONTE ORQUIDEAS'} ,
{"clave": '865', "nombre": 'SAN CARLOS'} ,
{"clave": '868', "nombre": 'EL CENTENARIO'} ,
{"clave": '867', "nombre": 'JUAN VARGAS'} ,
{"clave": '852', "nombre": 'BELLA LUNA'} ,
{"clave": '854', "nombre": 'LA FLORESTA'} ,
{"clave": '853', "nombre": 'VALLE PARAISO'} ,
{"clave": '856', "nombre": 'JARDINES DEL VALLE'} ,
{"clave": '855', "nombre": 'MIRAVALLE'} ,
{"clave": '858', "nombre": 'TERRAPLENA'} ,
{"clave": '857', "nombre": 'ORQUIDEAS RESIDENCIAL'} ,
{"clave": '860', "nombre": 'SENDEROS'} ,
{"clave": '859', "nombre": 'LOS ALTOS'} ,
{"clave": '862', "nombre": 'SAN MIGUEL'} ,
{"clave": '861', "nombre": 'LOMAS DEL CARMEN'} ,
{"clave": '864', "nombre": 'VISTA HERMOSA'} ,
{"clave": '863', "nombre": 'LOS BARCINOS'} ,
    ],
    min: [
      {"clave": '800', "nombre": 'AGUA SALADA'} ,
{"clave": '799', "nombre": 'SAN ANTONIO'} ,
{"clave": '694', "nombre": 'CARLOS DE LA MADRID VEJAR'} ,
{"clave": '693', "nombre": 'LOS COPALES I'} ,
{"clave": '696', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '695', "nombre": 'INFONAVIT'} ,
{"clave": '698', "nombre": 'SANTA TERESITA'} ,
{"clave": '697', "nombre": 'CENTRO'} ,
{"clave": '700', "nombre": 'ANTORCHISTA'} ,
{"clave": '699', "nombre": 'LOS MANGOS'} ,
{"clave": '701', "nombre": 'PROGRESO'} ,
{"clave": '608', "nombre": 'LA LOMA'} ,
{"clave": '270', "nombre": 'PATICAJO'} ,
{"clave": '109', "nombre": 'POBLADO BENITO JUAREZ PEÑA COLORADA'} ,
    ],
    mun: [
      {
          "clave": "1",
          "nombre": "Armería"
      },
      {
          "clave": "2",
          "nombre": "Colima"
      },
      {
          "clave": "3",
          "nombre": "Comala"
      },
      {
          "clave": "4",
          "nombre": "Coquimatlán"
      },
      {
          "clave": "5",
          "nombre": "Cuauhtémoc"
      },
      {
          "clave": "6",
          "nombre": "Ixtlahuacán"
      },
      {
          "clave": "7",
          "nombre": "Manzanillo"
      },
      {
          "clave": "8",
          "nombre": "Minatitlán"
      },
      {
          "clave": "9",
          "nombre": "Tecomán"
      },
      {
          "clave": "10",
          "nombre": "Villa de Álvarez"
      }
    ],
    tec: [
      {"clave": '818', "nombre": 'CONACAR - PRI'} ,
{"clave": '820', "nombre": 'EL PEDREGAL'} ,
{"clave": '819', "nombre": 'ELIAS LOZANO MERINO'} ,
{"clave": '822', "nombre": 'MADRID'} ,
{"clave": '821', "nombre": 'ARTICULO 27 CONSTITUCIONAL'} ,
{"clave": '794', "nombre": 'LEONARDO JARAMILLO'} ,
{"clave": '796', "nombre": 'SALAZAR SALAZAR'} ,
{"clave": '795', "nombre": 'BAYARDO'} ,
{"clave": '798', "nombre": 'EL OLIVO'} ,
{"clave": '797', "nombre": 'SAN MARTIN DE PORRES'} ,
{"clave": '710', "nombre": 'VALLE LAS PALMAS'} ,
{"clave": '686', "nombre": 'LA CUARTA'} ,
{"clave": '658', "nombre": 'BOSQUE DE SAN ANTONIO'} ,
{"clave": '669', "nombre": 'LA UNION'} ,
{"clave": '626', "nombre": 'SAN FELIPE'} ,
{"clave": '627', "nombre": 'PROGRESO'} ,
{"clave": '630', "nombre": 'AMPLIACIO SAN PEDRO'} ,
{"clave": '596', "nombre": 'LLANOS DE SAN JOSE'} ,
{"clave": '600', "nombre": 'EL LIMONERO'} ,
{"clave": '602', "nombre": 'COFRADIA DE HIDALGO'} ,
{"clave": '590', "nombre": 'PUERTAS CUATAS'} ,
{"clave": '589', "nombre": 'GRACIANO SANCHEZ'} ,
{"clave": '324', "nombre": 'CALERAS'} ,
{"clave": '323', "nombre": 'PABLO SILVA GARCIA'} ,
{"clave": '328', "nombre": 'EJIDO LA SALADA'} ,
{"clave": '330', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '329', "nombre": 'INDECO CALERAS'} ,
{"clave": '331', "nombre": 'LAZARO CARDENAS'} ,
{"clave": '307', "nombre": 'DIVISION DEL NORTE'} ,
{"clave": '316', "nombre": 'EL EDEN'} ,
{"clave": '320', "nombre": 'TECOLAPA'} ,
{"clave": '292', "nombre": 'LAS FLORES'} ,
{"clave": '291', "nombre": 'REAL DEL VALLE'} ,
{"clave": '299', "nombre": 'SAN IGNACIO'} ,
{"clave": '277', "nombre": 'LA UNION SUR'} ,
{"clave": '266', "nombre": 'JARDINES DEL CHAMIZAL'} ,
{"clave": '246', "nombre": 'BUGAMBILIAS'} ,
{"clave": '248', "nombre": 'FRANCISCO VILLA'} ,
{"clave": '247', "nombre": 'SANTA ELENA NORTE'} ,
{"clave": '250', "nombre": 'SOR JUANA INES DE LA CRUZ'} ,
{"clave": '249', "nombre": 'NIÑOS HEROES'} ,
{"clave": '252', "nombre": 'ALVARO OBREGON'} ,
{"clave": '251', "nombre": 'LAZARO CARDENAS I'} ,
{"clave": '254', "nombre": 'PALMARES'} ,
{"clave": '253', "nombre": 'MERCADO SORIANA'} ,
{"clave": '225', "nombre": 'REAL DEL BOSQUE'} ,
{"clave": '228', "nombre": 'GRISELDA ALVAREZ'} ,
{"clave": '227', "nombre": 'SAN PEDRO'} ,
{"clave": '234', "nombre": 'VALLE QUERIDO'} ,
{"clave": '210', "nombre": 'LUIS DONALDO COLOSIO'} ,
{"clave": '209', "nombre": 'PALMA REAL'} ,
{"clave": '211', "nombre": 'TEPEYAC'} ,
{"clave": '214', "nombre": 'NORIEGA PIZANO'} ,
{"clave": '213', "nombre": 'COFRADIA DE JUAREZ'} ,
{"clave": '216', "nombre": 'GRAL MANUEL ALVAREZ'} ,
{"clave": '218', "nombre": 'AMALIA SOLORZANO'} ,
{"clave": '217', "nombre": 'MIGUEL HIDALGO'} ,
{"clave": '220', "nombre": 'ELBA CECILIA VEGA'} ,
{"clave": '219', "nombre": 'JOSEFA ORTIZ DE DOMINGUEZ'} ,
{"clave": '196', "nombre": 'INFONAVIT LAS HUERTAS'} ,
{"clave": '197', "nombre": 'LA UNION NORTE'} ,
{"clave": '199', "nombre": 'VICENTE GUERRERO'} ,
{"clave": '202', "nombre": 'REYES HEROLES'} ,
{"clave": '201', "nombre": 'SANTA ELENA SUR'} ,
{"clave": '204', "nombre": 'LOMAS DEL VALLE'} ,
{"clave": '205', "nombre": 'OROFLOR'} ,
{"clave": '178', "nombre": 'LA PALMITA'} ,
{"clave": '180', "nombre": 'LIBERTAD'} ,
{"clave": '179', "nombre": 'BENITO JUAREZ'} ,
{"clave": '181', "nombre": 'INDECO'} ,
{"clave": '184', "nombre": 'VILLA FLORIDA'} ,
{"clave": '183', "nombre": 'HACIENDA LA LOMA'} ,
{"clave": '186', "nombre": 'EL CHAMIZAL'} ,
{"clave": '188', "nombre": 'JARDINES DE TECOMAN'} ,
{"clave": '187', "nombre": 'LA FLORESTA'} ,
{"clave": '189', "nombre": 'EMILIANO ZAPATA (SAN ISIDRO)'} ,
{"clave": '162', "nombre": 'DIAZ ORDAZ'} ,
{"clave": '161', "nombre": 'SAN CARLOS'} ,
{"clave": '164', "nombre": 'VILLAS DEL REY'} ,
{"clave": '163', "nombre": 'VILLAS DEL CENTENARIO'} ,
{"clave": '166', "nombre": 'PARQUE LAS FLORES (ELIAS ZAMORA VERDUZCO)'} ,
{"clave": '165', "nombre": 'LA FLORESTA II'} ,
{"clave": '168', "nombre": 'SAN MIGUEL'} ,
{"clave": '169', "nombre": 'LAS PALMAS'} ,
{"clave": '172', "nombre": 'PABLO SILVA GARCIA'} ,
{"clave": '171', "nombre": 'ESTATUTO JURIDICO'} ,
{"clave": '173', "nombre": 'PONCIANO ARRIAGA'} ,
{"clave": '175', "nombre": 'INFONAVIT LAS PALMAS'} ,
{"clave": '150', "nombre": 'MARIA ESTHER ZUNO DE ECHEVERRIA'} ,
{"clave": '152', "nombre": 'POBLADO DE LA ESTACION NUEVO CAXITLAN'} ,
{"clave": '154', "nombre": 'NUEVO C DE P RUIZ CORTINEZ (SANTA ROSA)'} ,
{"clave": '158', "nombre": 'SAN JOSE'} ,
{"clave": '157', "nombre": 'VILLAS DEL SOL'} ,
{"clave": '160', "nombre": 'PRIMAVERAS DEL REAL'} ,
{"clave": '159', "nombre": 'TUXPAN'} ,
{"clave": '99', "nombre": 'L MORENO'} ,
{"clave": '106', "nombre": 'CENTRO'} ,
{"clave": '110', "nombre": 'COFRADIA DE MORELOS'} ,
{"clave": '4', "nombre": 'CERRO DE ORTEGA'} ,
{"clave": '3', "nombre": 'EL TECOLAPA'} ,
{"clave": '6', "nombre": 'LEAÑOS'} ,
{"clave": '5', "nombre": 'EL ROCIO'} ,
{"clave": '8', "nombre": 'MARTIN DEL CAMPO'} ,
{"clave": '7', "nombre": 'SAGRADO CORAZON DE JESUS'} ,
{"clave": '10', "nombre": 'LINDA VISTA'} ,
{"clave": '9', "nombre": '10 DE MAYO'} ,
{"clave": '12', "nombre": 'SAN ANTONIO'} ,
{"clave": '11', "nombre": 'EL BORDO'} ,
{"clave": '13', "nombre": 'SAN MIGUEL DEL OJO DE AGUA'} ,
{"clave": '887', "nombre": 'SAN RAMON'} ,
    ],
    vil: [
      {"clave": '845', "nombre": 'REAL DE CANA'} ,
{"clave": '848', "nombre": 'JARDINES DE BUGAMBILIAS'} ,
{"clave": '847', "nombre": 'COLINAS DEL REAL'} ,
{"clave": '830', "nombre": 'PUERTA DEL CENTENARIO II'} ,
{"clave": '831', "nombre": 'RINCONADA DE LA GRANJA'} ,
{"clave": '770', "nombre": 'LA RESERVA'} ,
{"clave": '769', "nombre": 'PATRIA RESIDENCIAL'} ,
{"clave": '772', "nombre": 'BUENAVISTA'} ,
{"clave": '771', "nombre": 'EL ESPINAL'} ,
{"clave": '773', "nombre": 'RESIDENCIAL TULIPANES'} ,
{"clave": '705', "nombre": 'REAL DEL SIETE'} ,
{"clave": '712', "nombre": 'BENITO JUAREZ'} ,
{"clave": '714', "nombre": 'RESIDENCIAL CRISMAT'} ,
{"clave": '713', "nombre": 'SAN RAMON'} ,
{"clave": '715', "nombre": 'LAS PRESAS'} ,
{"clave": '717', "nombre": 'REAL DE SAN JORGE'} ,
{"clave": '704', "nombre": 'HACIENDA LOS LIMONES'} ,
{"clave": '673', "nombre": 'LINDAVISTA II'} ,
{"clave": '661', "nombre": 'ROSARIO IBARRA DE PIEDRA'} ,
{"clave": '668', "nombre": 'PUERTA HIGUERAS'} ,
{"clave": '632', "nombre": 'FRANCISCO HERNANDEZ ESPINOSA'} ,
{"clave": '613', "nombre": 'SANTA FE'} ,
{"clave": '616', "nombre": 'QUINTA SANTA ANITA'} ,
{"clave": '618', "nombre": 'TIERRA BLANCA'} ,
{"clave": '617', "nombre": 'RESIDENCIAL CUMBRES'} ,
{"clave": '620', "nombre": 'REAL DEL SEIS'} ,
{"clave": '622', "nombre": 'VILLAS PROVIDENCIA'} ,
{"clave": '624', "nombre": 'LAS ROSAS'} ,
{"clave": '623', "nombre": 'LA CAJITA DE AGUA'} ,
{"clave": '594', "nombre": 'PARQUE INDUSTRIAL'} ,
{"clave": '593', "nombre": 'JOSE HUMBERTO GUTIERREZ CORONA'} ,
{"clave": '598', "nombre": 'VALLE DEL SOL'} ,
{"clave": '599', "nombre": 'PUNTA DIAMANTE'} ,
{"clave": '606', "nombre": 'LINDAMAR'} ,
{"clave": '605', "nombre": 'VILLAFUENTES'} ,
{"clave": '607', "nombre": 'LINDAVISTA'} ,
{"clave": '578', "nombre": 'BUROCRATAS DEL ESTADO'} ,
{"clave": '577', "nombre": 'LEANDRO VALLE'} ,
{"clave": '580', "nombre": 'VERDE VALLE'} ,
{"clave": '579', "nombre": 'PALO ALTO'} ,
{"clave": '582', "nombre": 'LA COMARCA'} ,
{"clave": '581', "nombre": 'VISTA VOLCANES'} ,
{"clave": '584', "nombre": 'VILLA FLORES'} ,
{"clave": '583', "nombre": 'EL HAYA'} ,
{"clave": '586', "nombre": 'JARDINES DEL CENTENARIO'} ,
{"clave": '585', "nombre": 'PUERTA DE HIERRO'} ,
{"clave": '588', "nombre": 'ALFONSO ROLON MICHEL'} ,
{"clave": '587', "nombre": 'RINCONADA DEL CENTENARIO'} ,
{"clave": '562', "nombre": 'COLINAS DE LAS LAGUNAS'} ,
{"clave": '561', "nombre": 'LAS LAGUNAS'} ,
{"clave": '564', "nombre": 'COLINAS DE LA CRUZ'} ,
{"clave": '563', "nombre": 'EL ANGEL'} ,
{"clave": '566', "nombre": 'SALOMON PRECIADO'} ,
{"clave": '565', "nombre": 'SOLIDARIDAD'} ,
{"clave": '568', "nombre": 'COLINAS DEL REY'} ,
{"clave": '567', "nombre": 'VILLAS DEL CAÑAVERAL'} ,
{"clave": '570', "nombre": 'LOMAS DE LA HERRADURA'} ,
{"clave": '569', "nombre": 'LOMA DORADA'} ,
{"clave": '572', "nombre": 'MONTELLANO'} ,
{"clave": '571', "nombre": 'COLINAS DEL SOL'} ,
{"clave": '574', "nombre": 'SANTA SOFIA'} ,
{"clave": '573', "nombre": 'REAL DE MINAS'} ,
{"clave": '576', "nombre": 'JARDINES DEL LLANO'} ,
{"clave": '575', "nombre": 'VILLA DE ALVAREZ'} ,
{"clave": '546', "nombre": 'SAN MIGUEL'} ,
{"clave": '547', "nombre": 'LAS PALOMAS'} ,
{"clave": '550', "nombre": 'LOMAS DE LA HIGUERA REAL DE MONTROY'} ,
{"clave": '549', "nombre": 'VILLAS DE SAN JOSE II'} ,
{"clave": '552', "nombre": 'FOVISSSTE VILLA IZCALLI'} ,
{"clave": '551', "nombre": 'VILLA IZCALLI CAXITLAN'} ,
{"clave": '554', "nombre": 'LOS ALMENDROS'} ,
{"clave": '553', "nombre": 'LOMA HERMOSA'} ,
{"clave": '556', "nombre": 'LOMAS DE LA VILLA'} ,
{"clave": '555', "nombre": 'LOMAS ALTAS'} ,
{"clave": '558', "nombre": 'CARLOS DE LA MADRID'} ,
{"clave": '557', "nombre": 'CRUZ DE COMALA'} ,
{"clave": '560', "nombre": 'VISTA BUGAMBILIAS'} ,
{"clave": '559', "nombre": 'SIERRA BONITA'} ,
{"clave": '538', "nombre": 'JUAN JOSE RIOS'} ,
{"clave": '537', "nombre": 'LOMAS DEL CENTENARIO'} ,
{"clave": '540', "nombre": 'SAN ISIDRO'} ,
{"clave": '539', "nombre": 'FLORESTA'} ,
{"clave": '542', "nombre": 'SANTA TERESA'} ,
{"clave": '514', "nombre": 'CAMPESTRE BUGAMBILIAS'} ,
{"clave": '513', "nombre": 'HACIENDA EL NOGAL'} ,
{"clave": '517', "nombre": 'VILLAS DEL RIO'} ,
{"clave": '520', "nombre": 'VILLAS DIAMANTE'} ,
{"clave": '526', "nombre": 'CENTRO'} ,
{"clave": '527', "nombre": 'RANCHO SANTO'} ,
{"clave": '506', "nombre": 'VILLA CARLO'} ,
{"clave": '509', "nombre": 'LA FRONTERA'} ,
{"clave": '512', "nombre": 'VILLAS ALAMEDAS'} ,
{"clave": '450', "nombre": 'ALFREDO V BONFIL'} ,
{"clave": '449', "nombre": 'GOLONDRINAS'} ,
{"clave": '452', "nombre": 'LAS AGUILAS'} ,
{"clave": '451', "nombre": 'REAL BUGAMBILIAS'} ,
{"clave": '453', "nombre": 'LOMA DE LAS FLORES'} ,
{"clave": '434', "nombre": 'LA HACIENDITA'} ,
{"clave": '433', "nombre": 'JOSE MA MORELOS'} ,
{"clave": '436', "nombre": 'SAN CARLOS'} ,
{"clave": '435', "nombre": 'ALTA VILLA'} ,
{"clave": '438', "nombre": 'EL LLANO'} ,
{"clave": '437', "nombre": 'EMILIANO ZAPATA'} ,
{"clave": '440', "nombre": 'PRADOS DE LA VILLA'} ,
{"clave": '439', "nombre": 'CAMPESTRE'} ,
{"clave": '442', "nombre": 'VILLAS DE LA TUNA'} ,
{"clave": '441', "nombre": 'VILLA DE ALBA'} ,
{"clave": '444', "nombre": 'SENDEROS DEL CARMEN'} ,
{"clave": '443', "nombre": 'RINCONADA DE LA HACIENDA'} ,
{"clave": '446', "nombre": 'ALFREDO V BONFIL'} ,
{"clave": '445', "nombre": 'COLINAS DEL CARMEN'} ,
{"clave": '448', "nombre": 'AZALEAS'} ,
{"clave": '447', "nombre": 'PRIMAVERAS'} ,
{"clave": '418', "nombre": 'LA TAPATIA'} ,
{"clave": '417', "nombre": 'SANTA CRISTINA'} ,
{"clave": '420', "nombre": 'JARDINES DE LA VILLA'} ,
{"clave": '419', "nombre": 'MANUEL ALVAREZ'} ,
{"clave": '422', "nombre": 'VILLAS DE SAN JOSE I'} ,
{"clave": '421', "nombre": 'AZTECA'} ,
{"clave": '424', "nombre": 'VILLAS COLIMAN'} ,
{"clave": '423', "nombre": 'RAMON SERRANO'} ,
{"clave": '426', "nombre": 'VILLAS DE ORO'} ,
{"clave": '425', "nombre": 'LA GLORIA'} ,
{"clave": '428', "nombre": 'ROSARIO IBARRA DE PIEDRA'} ,
{"clave": '427', "nombre": 'DEL VALLE'} ,
{"clave": '430', "nombre": 'VILLAS DEL CENTRO'} ,
{"clave": '429', "nombre": 'LIBERACION'} ,
{"clave": '432', "nombre": 'SANTA MARTHA'} ,
{"clave": '431', "nombre": 'ADOLFO LOPEZ MATEOS'} ,
{"clave": '402', "nombre": 'LA JOYA'} ,
{"clave": '401', "nombre": 'RANCHO BLANCO'} ,
{"clave": '404', "nombre": 'RESIDENCIAL TABACHINES'} ,
{"clave": '403', "nombre": 'LOMA BONITA'} ,
{"clave": '405', "nombre": 'HACIENDA DEL CORTIJO'} ,
{"clave": '408', "nombre": 'SENDEROS DE RANCHO BLANCO'} ,
{"clave": '410', "nombre": 'VILLAS RANCHO BLANCO'} ,
{"clave": '409', "nombre": 'CAMINO ANTIGUO'} ,
{"clave": '411', "nombre": 'LOS OLIVOS'} ,
{"clave": '416', "nombre": 'MANUEL M DIEGUEZ'} ,
{"clave": '394', "nombre": 'EL CENTENARIO'} ,
{"clave": '393', "nombre": 'EL CENTENARIO II'} ,
{"clave": '396', "nombre": 'PUERTA DEL CENTENARIO'} ,
{"clave": '395', "nombre": 'LOS TRIANGULOS'} ,
{"clave": '397', "nombre": 'REAL CENTENARIO'} ,
{"clave": '356', "nombre": 'LA LIMA'} ,
{"clave": '360', "nombre": 'EL NARANJAL'} ,
{"clave": '362', "nombre": 'JOYITAS'} ,
{"clave": '361', "nombre": 'JULUAPAN'} ,
{"clave": '363', "nombre": 'NUEVO NARANJAL'} ,
{"clave": '367', "nombre": 'EL CHIVATO'} ,
{"clave": '350', "nombre": 'COLINAS DE LA JOYA'} ,
{"clave": '351', "nombre": 'CALLEJON DE LAS HUERTAS'} ,
{"clave": '322', "nombre": 'LAS COLONIAS'} ,
{"clave": '325', "nombre": 'AGUA DULCE'} ,
{"clave": '332', "nombre": 'PUEBLO NUEVO'} ,
{"clave": '2', "nombre": 'PUERTA DE ROLON'} ,
{"clave": '1', "nombre": 'HIGUERAS DEL ESPINAL'} ,
{"clave": '922', "nombre": 'LA COMARCA II'} ,
{"clave": '921', "nombre": 'RESIDENCIAL LOS ROBLES'} ,
{"clave": '886', "nombre": 'EL NUEVO HAYA'} ,
{"clave": '888', "nombre": 'LA FORTUNA'} ,
{"clave": '850', "nombre": 'REAL HACIENDA, SECCION HACIENDA DEL CARMEN'} ,
{"clave": '849', "nombre": 'VILLAS BUGAMBILIAS'} ,
{"clave": '851', "nombre": 'ARBOLEDAS DEL CARMEN'} ,
{"clave": '836', "nombre": 'EL MIXCOATE'} ,
{"clave": '840', "nombre": 'RESIDENCIAL REAL SANTA FE'} ,
{"clave": '839', "nombre": 'BUGAMBILIAS'} ,
{"clave": '842', "nombre": 'REAL HACIENDA, SECCION BOSQUE REAL'} ,
{"clave": '841', "nombre": 'LAS COLINAS'} ,
{"clave": '844', "nombre": 'REAL HACIENDA, SECCION REAL HACIENDA'} ,
{"clave": '843', "nombre": 'LAS PAROTAS'} ,
{"clave": '846', "nombre": 'FRACC REAL HACIENDA'} ,
    ],
    ton: [
      {"clave": '1491', "nombre": 'SAN MARCOS'} ,
{"clave": '1016', "nombre": 'TENEXCAMILPA'} ,
{"clave": '2451', "nombre": 'SAN MARCOS'} ,
{"clave": '1410', "nombre": 'FIDEL VELAZQUEZ'} ,
{"clave": '1409', "nombre": 'EL PAREJITO'} ,
{"clave": '1412', "nombre": 'EL PARLAMENTO'} ,
{"clave": '2767', "nombre": 'LA ESPERANZA'} ,
{"clave": '1411', "nombre": 'EL TESTERASO'} ,
{"clave": '1414', "nombre": 'INDEPENDENCIA'} ,
{"clave": '1413', "nombre": 'LA QUINTA'} ,
{"clave": '1416', "nombre": 'ZAGUAN AZUL'} ,
{"clave": '1415', "nombre": 'LA CAJITA'} ,
{"clave": '1418', "nombre": 'PROGRESO'} ,
{"clave": '1417', "nombre": 'EL GUAYABILLO'} ,
{"clave": '1419', "nombre": 'NUEVO'} ,
{"clave": '2972', "nombre": 'FIDEL VELAZQUEZ'} ,
{"clave": '4046', "nombre": 'CENTRO'} ,
{"clave": '4048', "nombre": 'CHUECO'} ,
{"clave": '2363', "nombre": 'YUYO'} ,
    ],
    _: []
  }

  module.exports = {
    municipiosArray,
    coloniasArray
    
   
  }