import React, {useState, useEffect} from "react";
import { Grid } from "@mui/material";



import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";


//QR Library
import { QrReader } from 'react-qr-reader';

import Html5QrcodePlugin from '../components/Html5QrcodePlugin';


//Other components
import NavBar from "../components/NavbarContainer";
import TicketSuccessDialog from '../components/TicketSuccessDialog'


//images
import qr from '../static/images/cards/qr.png'

//API
import { queryTrips, scanCode, startTrip, endTrip } from '../api/services/operatorAPI'

/*
const viajes = [
    'viaje1',
    'viaje2',
    'viaje3',
    'viaje4'
]
*/

/*
const synth = window.speechSynthesis;

let voices;
function loadVoices() {
  voices = synth.getVoices();
	console.log(voices)
}

if ("onvoiceschanged" in synth) {
  synth.onvoiceschanged = loadVoices;
} else {
  loadVoices();
}
*/
const synth = window.speechSynthesis;

const Page1 = () => {

    const [viaje, setViaje] = useState('0');
    const [data, setData] = useState('Sin resultados')
    const [viajes, setViajes] = useState([])

    const [isScan, setIsScan] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [codeMsg, setCodeMsg] = useState({})

    const [tripStatus, setTripStatus] = useState(0)

    const [result, setResult] = useState("No result");

    const [open, setOpen] = useState(false)
    
   const [textValue, setTextValue] = useState('');
  const [selectedVoice, setSelectedVoice] = useState(0);

   const [totalAsientos, setTotalAsientos] = useState(0);
	const [preAsientos, setPreAsientos] = useState(0);
	const [voices, setVoices] = useState(0);
	let handleScan = data => {
    if (data) {
      setResult(data);
      console.log(data)
    }
    };

    let handleError = err => {
     alert(err);
    };


    const handleChange = (e) => {
        
        setViaje(e.target.value);
        console.log(viaje)
        console.log(e.target.value)
    }
    const scan = (e) => {
        setIsScan(!isScan)
        setCodeMsg({  msg: '', key: new Date(), status:'' })
        setOpen(false)
        setData('')

    }  

    const getTripList = async()=>{
        let tripsResponse = await queryTrips()
        setViajes(tripsResponse.data.data)

    }
	const getVoices = () =>{
  const voicesget = synth.getVoices();
		setVoices(voicesget)
	}
	const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        console.log(decodedText)
      //  console.log(decodedResult)
	if(decodedText)	setData(decodedText)
    };

    const checkCode = async(code,idviaje)=>{
        let objCode = {
            hash:code,
            idviaje:idviaje
        }

        let responseCode
	   
    
        
        try{
            responseCode = await scanCode(objCode)
            console.log(responseCode)
            setCodeMsg({msg: responseCode.data.msg, key: new Date(), status:responseCode.data.status})
            setCodeError(false)
            setOpen(true)
		var utterance = new SpeechSynthesisUtterance(`Boleto valido ${responseCode.data.msg}`);
    utterance.voice = voices[selectedVoice];

    synth.speak(utterance);
            
        }catch(err){
            setCodeError(true)
            setCodeMsg({  msg: err.data.msg, key: new Date(), status:err.data.status })
		var utterance = new SpeechSynthesisUtterance(`Boleto inválido ${err.data.msg}`);
    utterance.voice = voices[selectedVoice];

    synth.speak(utterance);
        }
        setIsScan(false)

    }


    const startTripHandler = async()=>{
        let startObj = {
            idviaje:viaje
        }


        try{
            let startResponse = await startTrip(startObj)
            setTripStatus(1)
        }catch(err){
            console.log(err)
        }
    }

    const endTripHandler = async()=>{
        let endObj = {
            idviaje:viaje
        }


        try{
            let endResponse = await endTrip(endObj)
            setTripStatus(2)
        }catch(err){
            console.log(err)
        }
    }

    //UseEffects

    useEffect(()=>{
        getTripList()
    },[]);

	useEffect(() => {
		getVoices();
	}, [])

    useEffect(()=>{
	    console.log(data)
        if(data != '' && data != 'Sin resultados'){
        setCodeError(false)
        checkCode(data,viaje)
        }
    },[data])

    useEffect(()=>{
        /*
        let auxAr = viajes.map((viajeI)=>{
            if(viajeI.idviaje === viaje) 
            return viaje
            })*/

            if(viajes.length > 0){
                let auxAr=  viajes.filter((viajeI) => { return viajeI.idviaje === viaje})
        
            console.log(auxAr[0].status)
            setTripStatus(auxAr[0].status)
            }
            
    },[viaje])

    return (
        <>
            <NavBar/>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center">


                {/**  CONTAINER LISTA DE VIAJES */}
                <Grid container direction="row" justifyContent="space-evenly">
                    <Grid item xs={4}>
                        <Typography variant="h5">Selecciona un viaje</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={viaje}
                            label="Viaje"
                            onChange={handleChange}
                            disabled={isScan}
                        >
                            <MenuItem value={'0'}>Selecciona un viaje</MenuItem>

                                {
                                    viajes.map((viaje, index) => (
                                        <MenuItem key={index} value={viaje.idviaje}>{viaje.partida}-{viaje.destino}, a las {viaje.hora_de_partida}</MenuItem>
                                            ))
                                }
                        </Select>
                    </Grid>
                </Grid>

                {/** container QR SCANNER */}

	    {/*    <Grid item md={12} >
	    {
                        isScan ? (
                            
                               
                                <div style={{ width: "100%", minHeigh: "15vh", minWidth:"20vw", height:"100%" }}>
                                <QrReader
                                delay={300}
                                onError={handleError}
                                onResult={(result, error) => {
                                    if (!!result) {
                                        setData(result?.text);
                                    }

                                    if (!!error) {
                                        //console.info(error);
                                    }
                                    }}
                                style={{ width: "100%", minHeigh: "15vh", minWidth:"20vw", height:"100%" }}
                                facingMode="environment"
                             />
                             
                                {/*}
                                <p>{data}</p>
                                */}
                                
	    {/*                 </div>
                        ) : (
                            <img src={qr} width={345} height={345} />
                        )     }
               
                    
                </Grid> */}
 <Grid item md={12} >

	    <div className="App">
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
        </div>
	    </Grid>


            </Grid>


            

            <Grid container spacing={2} justifyContent="center">
                
                <Grid item container md={6} justifyContent="space-evenly" alignItems="center">
                    
                <Grid item md={6}>
                        <Typography>Válido/Inválido: </Typography>
                        {codeMsg.status === 'success'? 
                            <Typography variant="h6" color="green">Boleto Valido </Typography>
                             
                            : codeMsg.status === 'error'? 
                                <Typography variant="h6" color="red">Boleto Invalido </Typography>
                            
                            : <></> 
                        }
                        
                       
                    </Grid>
                    <Grid item md={6}>
                        <Typography>Estatus del boleto: </Typography>
                    <Typography variant="h6" color={codeMsg.status === 'success'? 'green' : 'red'}> {codeMsg.msg}</Typography>

                    </Grid>
                    
                </Grid>
	    {/*  <Grid item container md={6} justifyContent="space-evenly" alignItems="center">
                    <Typography>Contador de boletos: {preAsientos} de {totalAsientos} </Typography>
                </Grid>
		*/}
            </Grid>

            <Grid container spacing={2} justifyContent="center" style={{marginTop: '30px'}}>
	    {/*         <Grid item md={3}>
                    <Button variant="contained" onClick={scan}>
                        {
                            isScan ? (
                                'Cancelar'
                            ) : (
                                'Escanear'
                            )
                        }
                    </Button>
                </Grid>*/}
                <Grid item md={3}>
                    {
                        tripStatus===0?
                        <Button variant="contained" color="success" onClick={startTripHandler}>Iniciar viaje</Button>
                        
                        :tripStatus===1?
                        <Button variant="contained" color="success" onClick={endTripHandler}>Terminar viaje</Button>
                        
                        :tripStatus===2?
                        <p>Viaje concluido</p>
                        :
                        <></>
                    }
                    
                </Grid>
                <TicketSuccessDialog open={open}
                setOpen={setOpen}></TicketSuccessDialog>
            </Grid>
        </>
    )

}

export default Page1;
