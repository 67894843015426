import axios, { instanceI as axiosImages } from '../axiosConfig';

  




export const createReservation = async (data) => {
  return await axios.post(`/reservas/createreservation`,data)
}

export const validatemMtricula = async (data) => {
  return await axios.post(`/reservas/validatematricula`,data)
}


export const filterTrips = async (data) => {
  return await axios.post(`/reservas/filtertrips`,data)
}

export const completeReservation = async (data) => {
  return await axios.post(`/reservas/completereservation`,data)
}

