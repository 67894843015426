import React, {useState, useEffect} from "react";
import { Grid } from "@mui/material";



import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";


//QR Library
import { QrReader } from 'react-qr-reader';



//Other components
import NavBar from "../components/NavbarContainer";

//import Html5QrcodePlugin from '../components/Html5QrcodeScannerPlugin';
import Html5QrcodePlugin from '../components/Html5QrcodePlugin';

//images
import qr from '../static/images/cards/qr.png'

//API
import { queryTrips, scanCode, startTrip, endTrip } from '../api/services/operatorAPI'

/*
const viajes = [
    'viaje1',
    'viaje2',
    'viaje3',
    'viaje4'
]
*/

const PruebaCamara = () => {

    const [viaje, setViaje] = useState('0');
    const [data, setData] = useState('Sin resultados')
    const [viajes, setViajes] = useState([])

    const [isScan, setIsScan] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [codeMsg, setCodeMsg] = useState({})

    const [tripStatus, setTripStatus] = useState(0)

    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        console.log(decodedText)
        console.log(decodedResult)
    };

    const handleChange = (e) => {
        
        setViaje(e.target.value);
        console.log(viaje)
        console.log(e.target.value)
    }
    const scan = (e) => {
        setIsScan(!isScan)
    }  

    const getTripList = async()=>{
        let tripsResponse = await queryTrips()
        setViajes(tripsResponse.data.data)

    }

    const checkCode = async(code,idviaje)=>{
        let objCode = {
            hash:code,
            idviaje:idviaje
        }

        let responseCode
        
        try{
            responseCode = await scanCode(objCode)
            console.log(responseCode)
            setCodeMsg({msg: responseCode.data.msg, key: new Date(), status:responseCode.data.status})
            setCodeError(false)
            
        }catch(err){
            setCodeError(true)
            setCodeMsg({  msg: err.data.msg, key: new Date(), status:err.data.status })
        }
        setIsScan(false)

    }


    const startTripHandler = async()=>{
        let startObj = {
            idviaje:viaje
        }


        try{
            let startResponse = await startTrip(startObj)
            setTripStatus(1)
        }catch(err){
            console.log(err)
        }
    }

    const endTripHandler = async()=>{
        let endObj = {
            idviaje:viaje
        }


        try{
            let endResponse = await endTrip(endObj)
            setTripStatus(2)
        }catch(err){
            console.log(err)
        }
    }

    //UseEffects

    useEffect(()=>{
        getTripList()
    },[]);

    useEffect(()=>{
        setCodeError(false)
        checkCode(data,viaje)

    },[data])

    useEffect(()=>{
        /*
        let auxAr = viajes.map((viajeI)=>{
            if(viajeI.idviaje === viaje) 
            return viaje
            })*/

            if(viajes.length > 0){
                let auxAr=  viajes.filter((viajeI) => { return viajeI.idviaje === viaje})
        
            console.log(auxAr[0].status)
            setTripStatus(auxAr[0].status)
            }
            
    },[viaje])

    return (
        <>
            <NavBar/>
            <Grid container justifyContent="space-evenly">
                <Grid item xs={4}>
                    <Typography variant="h5">Selecciona un viaje</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={viaje}
                        label="Viaje"
                        onChange={handleChange}
                        disabled={isScan}
                    >
                        <MenuItem value={'0'}>Selecciona un viaje</MenuItem>
                        {
                            viajes.map((viaje, index) => (
                                <MenuItem key={index} value={viaje.idviaje}>{viaje.partida}-{viaje.destino}, a las {viaje.hora_de_partida}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                <Grid item md={3}>
                <div className="App">
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
        </div>
                </Grid>
                <Grid item md={3}>
                    {
                        isScan ? (
                            <>
                                <QrReader
                                    constraints={{
                                        facingMode: 'environment'
                                    }}
                                    onResult={(result, error) => {
                                    if (!!result) {
                                        setData(result?.text);
                                    }

                                    if (!!error) {
                                        //console.info(error);
                                    }
                                    }}
                                    style={{ width: '100%' }}
                                />
                                {/*}
                                <p>{data}</p>
                                */}
                                
                            </>
                        ) : (
                            <img src={qr} width={345} height={345} />
                        )
                    }
               
                    
                </Grid>
                <Grid item container md={6} justifyContent="space-evenly" alignItems="center">
                    
                <Grid item md={4}>
                        <Typography>Válido/Inválido: </Typography>
                        {codeMsg.status === 'success'? 
                            <Typography variant="h6" color="green">Boleto Valido </Typography>
                             
                            : codeMsg.status === 'error'? 
                                <Typography variant="h6" color="red">Boleto Invalido </Typography>
                            
                            : <></> 
                        }
                        
                       
                    </Grid>
                    <Grid item md={4}>
                        <Typography>Estatus del boleto: </Typography>
                    <Typography variant="h6" color={codeMsg.status === 'success'? 'green' : 'red'}> {codeMsg.msg}</Typography>

                    </Grid>
                    
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" style={{marginTop: '30px'}}>
                <Grid item md={3}>
                    <Button variant="contained" onClick={scan}>
                        {
                            isScan ? (
                                'Cancelar'
                            ) : (
                                'Escanear'
                            )
                        }
                    </Button>
                </Grid>
                <Grid item md={3}>
                    {
                        tripStatus===0?
                        <Button variant="contained" color="success" onClick={startTripHandler}>Iniciar viaje</Button>
                        
                        :tripStatus===1?
                        <Button variant="contained" color="success" onClick={endTripHandler}>Terminar viaje</Button>
                        
                        :tripStatus===2?
                        <p>Viaje concluido</p>
                        :
                        <></>
                    }
                    
                </Grid>
               
                
            </Grid>
        </>
    )

}

export default PruebaCamara;