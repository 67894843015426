import axios from 'axios';

const type = 'prod'
const BASE_URL = type === 'prod' ? `https://${window.location.host}/api` : 'https://localhost:4000'

// JSON Instance
axios.defaults.withCredentials = true
const instance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
})

// Interceptor para enviar datos recibidos y error 401, 403
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error && error.response) {
      if (error.response.status === 401 && error.response.config.url !== '/user/getUserInfo') {
        console.log('Error 401 - Unauthorized');
        window.location.replace('/');
      }
      if (error.response.status === 403) {
        console.log('Error 403 - Forbidden');
        window.location.replace('/');
      }
      // if (error.response.status === 404) {
      //   console.log('Error 404 Recurso no encontrado');
      //   alert('404 Recurso no encontrado');
      // }
    }
    return Promise.reject(error.response);
  },
);

// FORM-DATA Instance
axios.defaults.withCredentials = true
const instanceI = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data',
  }
})

// Interceptor para enviar datos recibidos y error 401, 403
instanceI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error && error.response) {
      if (error.response.status === 401 && error.response.config.url !== '/user/getUserInfo') {
        console.log('Error 401 - Unauthorized');
        //window.location.replace('/');
      }
      if (error.response.status === 403) {
        console.log('Error 403 - Forbidden');
        //window.location.replace('/');
      }
      // if (error.response.status === 404) {
      //   console.log('Error 404 Recurso no encontrado');
      //   alert('404 Recurso no encontrado');
      // }
    }
    return Promise.reject(error.response);
  },
);

export default instance;
export { instanceI };

