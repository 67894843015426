import React, {useState, useEffect} from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete'

import Swal from "sweetalert2";



//Other components
import NavBar from "../components/NavbarContainer";

//images
import qr from '../static/images/cards/qr.png'

//API
import { getUserByRol, deleteUser } from "../api/adminAPI";


function createData(email, nombre, apellido, sexo) {
    return { email, nombre, apellido, sexo };
}

const rows = [
    createData('example1@gmail.com', 'Luis', 'Vazquez', 'M'),
    createData('example2@gmail.com', 'Maria', 'Morales', 'F'),
    createData('example3@gmail.com', 'Luisa', 'Ortiz', 'F'),
  ];
  


const Depurar = () => {

    const [users, setUsers] = useState([])
    const navigate = useNavigate();
    const [globalError, setGlobalError] = useState({});
    const [open, setOpen] = useState(false);

    let { userRol } = useParams();

    const getUsers = async() => {

        try {
        
        const users = await getUserByRol({rol: userRol});
        setUsers(users.data.data)
        console.log(users)


        } catch (error) {
        console.log(error)
        setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
          setOpen(true) 
        }
    }

    // Handle for close
    const handleClose = () => {

        setOpen(false)

    };

    const deleteUserF = async (id) => {
        console.log("entra borrar")
        try {
            
            const responseDelete =  await deleteUser({idusuario: id})
            console.log(responseDelete)

            setGlobalError({ open: true, msg: responseDelete.data.msg, key: new Date(), status:responseDelete.data.status })
          setOpen(true)      

          setTimeout(()=> {
            window.location.reload(true)
           }, 1500);

        } catch (error) {
            setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
          setOpen(true) 
            return error
        }
    }
     

    const handleDelete = (userId) => {

        Swal.fire({
            title: '¿Estás seguro de eliminar?',
            text: "Una vez aceptado, se borrará la información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
            }).then((result) => {
            if (result.isConfirmed) {
                return deleteUserF(userId)
            }
            })

    }

    useEffect(() => {
        getUsers()
    }, [])


    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} justifyContent="end" style={{marginBottom: '20px'}}>
                <Grid item md={3}>
                    <Button variant="contained" color="success" onClick={() => navigate(`/nuevousuario/${userRol}`)}>+ Nuevo usuario</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell >Apellidos</TableCell>
                                <TableCell >Sexo</TableCell>
                                <TableCell >Editar</TableCell>
                                <TableCell >Borrar</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {users.map((row) => (
                                <TableRow
                                key={row.email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.email}
                                </TableCell>
                                <TableCell >{row.nombre}</TableCell>
                                <TableCell >{row.apellido_p} {row.apellido_m}</TableCell>
                                <TableCell >{row.sexo}</TableCell>
                                <TableCell >
                                    <IconButton
                                        onClick={() => navigate(`/editarusuario/${userRol}/${row.idusuario}`)}
                                    >
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDelete(row.idusuario)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        
      >
        <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
      </Snackbar>

            </Grid>
        </>
    )

}

export default Depurar;