import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { userInfoSession } from '../api/services/userAPI'

//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

//Router
import { useNavigate, Link } from 'react-router-dom';

//Reducer
import { deleteInfo } from '../redux/useReducer';

//API
import { login } from '../api/services/userAPI';

//img
import img1 from '../static/images/cards/ESTUD.png'
import img2 from '../static/images/cards/camion.png'
import img3 from '../static/images/cards/3.png'





function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
});
const CssTextField = styled(TextField)({
    // '& label': {
    //   color: 'transparent'
    // },
    '& .css-1wc848c-MuiFormHelperText-root': {
      color: 'red',
      paddingLeft: '2%',
      fontSize: '14px'
    },
    '& label.MuiInputLabel-shrink': {
      color: 'transparent',
    },
    
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  });
export default function Login() {

    const isSm = useMediaQuery(theme.breakpoints.down('md'))
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    //States
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
  
  
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
  
    const [loading, setLoading] = useState(false)
  
    

    // Styles
    const styles ={
      
      imglogocontainer:{
        width: '100%',
        height: '35vh'
      },
      imglogo:{
        width: '100%',
        height: '100%'
      },
      formContainer: {
        
        backgroundColor:  '#19B2D7',
        paddingTop: '5%',
        color: 'white',
        marginTop: '-20px',
        borderRadius: '2%'
      },
      field: {
        // width: '200%',
        color: 'white',
        backgroundColor: 'white',
        borderRadius: '10px',
        marginBottom: '20px'
      },
      main: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#D6EBF4'
      }
  
      
    }
    
    // ------------------ Handlers -------------
    const submitHandler = async () => {
    
    setLoading(true)

    //Validation
    /*
    if(!validateEmail(email) ){
        setErrorEmail('Email incorrecto')
    }*/
    if(password.length < 4){
        setErrorPassword('La contraseña es de al menos 8 caracteres')
    }

    const dataUser = {
        user: email,
        contrasenia: password
    }

    
    dispatch(deleteInfo())
    
    try{
      const response = await login(dataUser)
      console.log(response)
      return navigate(`/home`)
    }
    catch(error){
        setMessage({ open: true, msg: error.data.msg, status:error.data.status })
        setOpen(true)
    }
   // dispatch(deleteInfo())

   /* 
   try{
        const response = await login(dataUser)
        console.log(response)
        Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Bienvenido!',
        showConfirmButton: false,
        timer: 1500
        })
        return navigate(`/mis-lugares`)
        }
        catch(error){
        //setMessage({ open: true, msg: error.data.msg })
        setOpen(true)
        }
    
    */
    console.log(dataUser)
        setLoading(false)
    

    

    }
    const handleUser = (e) => {

        setEmail(e.target.value)
        setErrorEmail('')
    
    }
    const handlePassword = (e) => {

        setPassword(e.target.value)
        setErrorPassword('')

    }
    const handleClose = () => {

        setOpen(false)

    };
  
const checkSession =  ()=>{
      try {
        //const response =  userInfo()
         console.log('response')
        
      } catch (err) {
        //setMessage({ open: true, msg: err.data.msg, key: new Date() })
        console.log(err)
      }
    }

    useEffect( ()=>{
      //checkSession()
      async function funcSession(){
        
        try{
        const response = await userInfoSession()
        console.log(response)
          if(response.data.data !== 'nomail'){
            return navigate(`/home`)
          }
        
      }catch(err){
        console.log(err)
        if (err.response.status == 401) {
          throw new Error(err);
        }

        
      }
    }
    funcSession();
    },[])

      
  
  
  
    return (
  
      <div style={styles.main}>

       
        {/* <div style={styles.imglogocontainer}>
        <img src={top} style={styles.imglogo}/>
      </div> */}
      <Grid container style={{height:"100vh", width: isSm ? "100vw" : "80vw", margin: '0 auto', }} justifyContent="center" alignItems='center'>
      
      <Grid item md={12}>
        <span style={{fontSize: 80, fontWeigth: 900, color: '#E98300'}}>Ruta</span>
        <span style={{fontSize: 40, fontWeigth: 100, marginRight: 50, color: '#E98300'}}>ESCOLAR</span>
      </Grid>
        {/** RIGHT CONTAINER */}
        <Grid  item container md={6} 
            // direction="column"
            justifyContent="center"
            // alignItems="center" 
            // spacing={3}
            style={styles.formContainer}
            >
          
          
          <Grid item xs={12} align="center">
            <Typography style={{fontSize: isSm ? '16px' : '24px', fontWeight: 'bold', marginBottom: '20px', color: '#000000'}} >  INICIAR SESIÓN </Typography>
          </Grid>
  
          <Grid item xs={10} md={8} >
            <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
              required
              id="outlined-required"
              label="Matricula o Email"
              onChange={handleUser}
              value={email}
              // error={errorUser !== ''}
              helperText={errorEmail}
              onKeyPress={(e) => e.key === "Enter" && submitHandler()}
  
  
            />
          </Grid>
  
          <Grid item xs={10} md={8}>
            <CssTextField
            color='secondary'
            style={styles.field}
            fullWidth
              required
              id="outlined-password-input"
              label="password"
              type="password"
              autoComplete="current-password"
              onChange={handlePassword}
              value={password}
              // error={errorPassword !== ''}
              helperText={errorPassword}
              onKeyPress={(e) => e.key === "Enter" && submitHandler()}
            />
          </Grid>
          <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
             <Link to={'/signup'} style={{fontSize: isSm ? '16px' : '18px'}}>¿Aún no tienes una cuenta?</Link> 
          </Grid>
          {/*
          <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
             <Link to={'/recover'} style={{fontSize: isSm ? '16px' : '18px'}}>Olvide mi contraseña</Link> 
          </Grid>
          */}
          
          <Grid item xs={12} container style={{marginBottom: '20px'}}>
            <Button 
              variant="contained"
              onClick={submitHandler}
              style={{background: 'rgba(0, 112,189,1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto', fontWeight: 'bold'}}
          >Entrar</Button>
           <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
            <img src={img2} alt="" width={'80%'} height={'90%'}  />
          </Grid>
          </Grid>
          {
          loading && (
            <Box sx={{ display: 'flex' }} >
              <CircularProgress />
            </Box>
          )
        }
        </Grid>

        <Grid  item container md={6} 
            // direction="column"
            justifyContent="center"
            // alignItems="center" 
            // spacing={3}
            style={{marginTop: isSm ? '0': '10px'}}
            >
          <img src={img1} alt="" width={isSm ? '30%': '80%'} height={isSm ? '30%': '80%'}/>
          
        </Grid>
         
  
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
          
        >
          <Alert severity={message.status} variant="filled" sx={{ width: '100%' }}>{message.msg}</Alert>
        </Snackbar>
  
      </Grid>
      </div>
      
    )
  }
