import React, {useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import EvenstSeat from '@mui/icons-material/EventSeat';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';


//Other components
import NavBar from "../components/NavbarContainer";

//images
import qr from '../static/images/cards/qr.png'


function createData(ruta, horario, ocupacion, disponibilidad) {
    return { ruta, horario, ocupacion, disponibilidad };
}

const rows = [
    createData('Colima - Cuauhtemoc', '10:00', 30, 15),
    createData('Colima - Cuauhtemoc', '13:00', 10, 7),
    createData('Cuauhtemoc - Colima', '14:00', 40, 0),
    createData('Colima - Cuauhtemoc', '16:00', 40, 0),
    createData('Cuauhtemoc - Colima', '10:00', 35, 0),
    createData('Cuauhtemoc - Colima', '18:00', 25, 9),
  ];
  


const Page8 = () => {

    const navigate = useNavigate();



    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} justifyContent="center" style={{marginBottom: '20px'}}>
            <Grid item md={4} style={{backgroundColor: '#D6EBF4', textAlign: 'center', borderRadius: 50, padding: 10}}>                    <Typography variant="h4" color='648A90'>Disponibilidad de viajes</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">Viaje</TableCell>
                                <TableCell align="center">Horario</TableCell>
                                <TableCell align="center">Ocupación</TableCell>
                                <TableCell align="center">Disponibilidad</TableCell>
                                <TableCell align="center">Estatus</TableCell>
                                <TableCell align="center">Ir a reservar</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row" align="center">
                                    {row.ruta}
                                </TableCell>
                                <TableCell align="center">{row.horario}</TableCell>
                                <TableCell align="center">{row.ocupacion}</TableCell>
                                <TableCell align="center">{row.disponibilidad}</TableCell>
                                <TableCell align="center">
                                    {row.disponibilidad ? 
                                        (
                                            <CheckCircle color="success" />
                                        ) : (
                                            <Cancel color="error"/>
                                        )
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton 
                                        onClick={() => navigate('/page5')}
                                        disabled={!row.disponibilidad}>
                                        <EvenstSeat />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>    
            </Grid>
        </>
    )

}

export default Page8;