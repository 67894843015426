import React, { useState, useEffect } from 'react'
import { createTheme, useMediaQuery } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
//import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';



import Swal from 'sweetalert2'

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const CssTextField = styled(TextField)({
  // '& label': {
  //   color: 'transparent'
  // },
  '& .css-1wc848c-MuiFormHelperText-root': {
    color: 'red',
    paddingLeft: '2%',
    fontSize: '14px'
  },
  '& label.MuiInputLabel-shrink': {
    color: 'transparent',
  },
  
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export default function PassRecover() {

    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const navigate = useNavigate();

  //States
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);


  const [errorEmail, setErrorEmail] = useState('');

  const [loading, setLoading] = useState(false)

    const styles ={
    
        imglogocontainer:{
          width: '100%',
          height: '35vh'
        },
        imglogo:{
          width: '100%',
          height: '100%'
        },
        formContainer: {
          backgroundColor: '#19B2D7',
          paddingBottom: '10%',
          paddingTop: '5%',
          color: 'white',
          marginTop: '-20px',
          borderRadius: '2%'
        },
        field: {
          // width: '200%',
          color: 'white',
          backgroundColor: 'white',
          borderRadius: '10px',
          marginBottom: '20px'
        }
    
        
      }


       //Handler for submit
       const submitHandler = async () => {
    
        setLoading(true)
      //Validation
      if(email){
        setErrorEmail('Usuario incorrecto')
      }
      
  
      const dataUser = {
          email,
          
      }
  
      
      
  
        setLoading(false)
     
  
      
  
      }
  
      // Handle for close
      const handleClose = () => {
  
          setOpen(false)
  
      };
  
      // Handle User
      const handleEmail = (e) => {
  
          setEmail(e.target.value)
          setErrorEmail('')
  
      }
  
      


  return (
    <>
    {/* <div style={styles.imglogocontainer}>
    <img src={top} style={styles.imglogo}/>
  </div> */}
  <Grid container style={{height:"100vh", width: isSm ? "100vw" : "80vw", margin: '0 auto'}} justifyContent="center" alignItems='center'>

    {/** RIGHT CONTAINER */}
    <Grid  item container md={6} 
        // direction="column"
        justifyContent="center"
        // alignItems="center" 
        // spacing={3}
        style={styles.formContainer}
        >
      
      
      <Grid item xs={12} align="center">
        <Typography style={{fontSize: isSm ? '16px' : '24px', fontWeight: 'bold', marginBottom: '20px', color: '#000000'}} >  RECUPERAR CONTRASEÑA </Typography>
      </Grid>

      <Grid item xs={10} md={8} >
        <CssTextField
        color='secondary'
        style={styles.field}
        fullWidth
          required
          id="outlined-required"
          label="CORREO ELECTRONICO"
          onChange={handleEmail}
          value={email}
          // error={errorUser !== ''}
          helperText={errorEmail}
          onKeyPress={(e) => e.key === "Enter" && submitHandler()}


        />
      </Grid>

     
      <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
         <Link to={'/signup'} style={{fontSize: isSm ? '16px' : '18px'}}>¿Aún no tienes una cuenta?</Link> 
      </Grid>
      <Grid item xs={12} textAlign="center" style={{margin: '20px 0'}}>
         <Link to={'/login'} style={{fontSize: isSm ? '16px' : '18px'}}>Iniciar sesión</Link> 
      </Grid>
      
      <Grid item xs={12} container style={{marginBottom: '20px'}}>
        <Button 
          variant="contained"
          onClick={submitHandler}
          style={{background: 'rgba(0, 112,189,1)', color: 'white', borderRadius: '100px', padding: '10px 30px', margin: '0 auto', fontWeight: 'bold'}}
      >Enviar</Button>
      </Grid>
      {
      loading && (
        <Box sx={{ display: 'flex' }} >
          <CircularProgress />
        </Box>
      )
    }
    </Grid>
    

    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
      
    >
      <Alert severity="error" variant="filled" sx={{ width: '100%' }}>Email incorrecto</Alert>
    </Snackbar>

  </Grid>
  </>
  )
}