import { useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';

//import { useSelector, useDispatch } from 'react-redux';

// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';

// Icons
import InputIcon from '@mui/icons-material/Input';
import avatar from '../static/images/cards/11.png'

// Components
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { deleteInfo } from '../redux/useReducer'

// API
import { logout } from '../api/services/userAPI'




export default function NavBar() {

  const dispatch = useDispatch();
  const {rol} = useSelector((state) => state.user);
 
    //Se debe consultar el user de la sesion
    //const user = {level:1};

  const smBreakpoint = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);


  // Functions
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }
  const fnLogout = async () => {
    try{
      console.log('logout')
      const response = await logout()
      dispatch(deleteInfo())
      navigate('/')
    }
    catch(e){
      console.log(e)
    }
    

  }


  return (
    <Box
      sx={{
        position: 'relative',
        marginBottom: 10
        //minHeight: '100vh',
        
      }}
    >
      <AppBar
        position="static"
        sx={{
          boxShadow: '0px 1px 8px 0px #bdbdbd',
          background: 'linear-gradient(90deg, rgba(252,144,0,1) 0%, rgba(248,209,0,1) 0%, rgba(233,131,0,1) 100%)',
          marginBottom: 5
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            variant="dense"
          >
            <MobileMenu 
              level={rol}
            />

            <DesktopMenu
              level={rol}
            />

            <Box
              sx={{
                flexGrow: 0,
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Tooltip title={`¡Bienvenido ${''}!`}>
                <IconButton
                  aria-controls="menu-user"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <Avatar  src={avatar}/>
                </IconButton>
              </Tooltip>
              <Menu
                keepMounted
                id="menu-user"
                anchorEl={anchorElUser}
                TransitionComponent={Fade}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                onClick={handleCloseUserMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={fnLogout}
                  component="button"
                  sx={{ gap: 1 }}
                >
                  <ListItemText primary="Cerrar Sesión" />
                  <ListItemIcon>
                    <InputIcon />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>

      <Container
        disableGutters={smBreakpoint}
        maxWidth={!smBreakpoint && 'xl'}
      >
        <Outlet />
      </Container>
    </Box>
  )
}