export const formatDate = (date) => {

    const newDate = date ? date : new Date(date)
    const year = newDate.getFullYear().toString()
    let month = newDate.getMonth()+1
    let day = newDate.getDate()

    if(month < 10) month = `0${month}`
    if(day < 10) day = `0${day}`

    const parseDate = `${year}-${month}-${day}`
    
    // // console.log(parseDate)
    return parseDate
}
export const formatTime = (time) => {

    const newDate = time ? time : new Date(time)
    let hour = newDate.getHours()
    let minutes = newDate.getMinutes()

    if(hour < 10) hour =`0${hour}`
    if(minutes < 10) minutes = `0${minutes}`
    const parseTime = hour + ":" + minutes
    return parseTime
}