import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


//Other components
import NavBar from "../components/NavbarContainer";
import CardsContainer from "../components/CardsContainer";

//Images
import qr from '../static/images/cards/codigo.png'
import resumen from '../static/images/cards/7.png'
import viajes from '../static/images/cards/9.png'
import disponibilidad from '../static/images/cards/8.png'
import ticket from '../static/images/cards/6.png'
import calendario from '../static/images/cards/5.png'
import student from '../static/images/cards/4.png'
import admins from '../static/images/cards/13.png'
import adminr from '../static/images/cards/12.png'
import operador from '../static/images/cards/administradorservicio.png'






const Home = () => {


    //const [rol, setRol] = useState(1)

    const dispatch = useDispatch();
    const {rol} = useSelector((state) => state.user);
    const userVar = useSelector((state) => state.user);
    console.log("variable sesion")
    console.log(userVar)

    const [cardsAdminReserva, setCardsAdminReserva] = useState([
        
        {
            path: '/reservarviaje',
            img: viajes,
            title: 'Reservar y pagar un viaje',
            legend: 'Da click para ayudar al estudiante a reservar un viaje'
        }, {
            path: '/page9',
            img: disponibilidad,
            title: 'Consultar Disponibilidad',
            legend: 'Da click para ver los viajes que se pueden resevar actualmente'
        },{
            path: '/page7',
            img: resumen,
            title: 'Ver resumenes de viajes',
            legend: 'Da click para ver todos los resumenes de los viajes.'
        },

    ])
    const [cardsEstudiante, setCardsEstudiante] = useState([
        {
            path: '/page6',
            img: ticket,
            title: 'Ver mis boletos',
            legend: 'Da click para ver tus boletos activos.'
        },
        {
            path: '/page9',
            img: disponibilidad,
            title: 'Consultar Disponibilidad',
            legend: 'Da click para ver los viajes que se pueden resevar actualmente'
        },

    ])
    const [cardsOperador, setCardsOperador] = useState([
        {
            path: '/page1',
            img: qr,
            title: 'Escanear un código',
            legend: 'Da click para escanear el códido del viaje.'
        },
    ])
    const [cardsAdminOperador, setCardsAdminOperador] = useState([
        {
            path: '/page2',
            img: calendario,
            title: 'Cargar horarios de viajes',
            legend: 'Da click para cargar un nuevo horario de ruta.'
        },
        {
            path: '/page7',
            img: resumen,
            title: 'Ver resumenes de viajes',
            legend: 'Da click para ver todos los resumenes de los viajes.'
        }
    ])
    const [cardsAdmin, setCardsAdmin] = useState([
        {
            path: '/depurar/0',
            img: student,
            title: 'Estudiantes',
        },
        {
            path: '/depurar/3',
            img: adminr,
            title: 'Administradores de reserva',
        },
        {
            path: '/depurar/1',
            img: operador,
            title: 'Operadores',
        },
        {
            path: '/depurar/2',
            img: admins,
            title: 'Administradores de servicio',
        },

    ])

    return (
        <>
            <NavBar/>

            {
                    rol  === 0 && (
                        <marquee style={{color:"red"}}>Horarios de atención: Biblioteca Cuauhtémoc de 7:00 am a 8:00 pm y Frente a la dirección en Colima de 6:00 am a 4:00 pm</marquee>
                    )
            }
            <div style={{backgroundColor: '#D6EBF4', width: '80vw', height: 'auto', margin: '30px auto', borderRadius: 50}}>
            
            <Grid container justifyContent="center" spacing={4}>
                {
                    rol  === 0 && (
                        <CardsContainer 
                        cards={cardsEstudiante}
                            
                        />   
                    )
                }
                {
                    rol === 1 && (
                        <CardsContainer 
                        cards={cardsOperador}
                            
                        />
                    )
                }
                {
                    rol === 2 && (
                        <CardsContainer 
                        cards={cardsAdminOperador}
                        />
                    )
                }
                {
                    rol === 3 && (
                        <CardsContainer 
                        cards={cardsAdminReserva}
                        />
                    )
                }
                {
                    rol === 4 && (
                        <CardsContainer 
                            cards={cardsAdmin}
                        />
                    )
                }
                

            </Grid>
            </div>
        </>
    )

}

export default Home;