// MUI
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Router
import Router from './utils/routes';

// Theme
import theme from './utils/theme';

// Store
import { Provider } from 'react-redux';
import store from './redux/store'


function App() {
  return (
    
    <StyledEngineProvider injectFirst>
        <Provider store={store} >
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
            <Router />
        </MuiThemeProvider>
        </Provider>
    </StyledEngineProvider> 
  );
}

export default App;
