import { BrowserRouter, Route, Routes } from 'react-router-dom';

//Components (routes)
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Pages
import Login from '../pages/Login'
import PassRecover from '../pages/PassRecover';
import SignUp from '../pages/Signup';
import Home from '../pages/Home';
import Page1 from '../pages/Page1';
import Page2 from '../pages/Page2';
import Page3 from '../pages/Page3';
import Page4 from '../pages/Page4';
import Page5 from '../pages/Page5';
import Page6 from '../pages/Page6';
import Page7 from '../pages/Page7';
import Page8 from '../pages/Page8';
import Page9 from '../pages/Page9';
import Depurar from '../pages/Depurar';
import NewUser from '../pages/NewUser';
import EditUser from '../pages/EditUser';
import EditTrip from '../pages/EditTrip';
import PruebaCamara from '../pages/PruebaCamara'

import ReservaViaje from '../pages/Reservas/ReservaViaje';

const Router = () => {
    return (
      <BrowserRouter>
        <Routes>
        
        
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />}/>
        <Route path='/recover' element={<PassRecover />} />
        <Route path='/pruebacamara' element={<PruebaCamara />} />

        <Route path='/home' element={<PrivateRoute Component={Home } /> } />

        <Route path='/page1' element={<PrivateRoute Component={Page1 } /> } />
        <Route path='/page2' element={<PrivateRoute Component={Page2} /> } />
        <Route path='/page3' element={<PrivateRoute Component={Page3} /> } />
        <Route path='/page4' element={<PrivateRoute Component={Page4} /> } />
        <Route path='/page5' element={<PrivateRoute Component={Page5} /> } />
        <Route path='/page6' element={<PrivateRoute Component={Page6} /> } /> 
        <Route path='/page7' element={<PrivateRoute Component={Page7} /> } />
        <Route path='/page8' element={<PrivateRoute Component={Page8} /> } />
        <Route path='/page9' element={<PrivateRoute Component={Page9} /> } />
        <Route path='/editarusuario/:userRol/:userId' element={<PrivateRoute Component={EditUser} /> } />

        <Route path='/reservarviaje' element={<PrivateRoute Component={ReservaViaje} /> } />
        <Route path='/edittrip' element={<PrivateRoute Component={EditTrip} /> } />
        

        <Route path='/editarusuario/:userRol' element={<PrivateRoute Component={EditUser} /> } />
        <Route path='/nuevousuario/:userRol' element={<PrivateRoute Component={NewUser} /> } />
        <Route path='/depurar/:userRol' element={<PrivateRoute Component={Depurar} /> } /> 
        </Routes>
        
      
      </BrowserRouter>
    );
  }
  
  export default Router;