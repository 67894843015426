import React, {useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Other components
import NavBar from "../components/NavbarContainer";

//images
import qr from '../static/images/cards/qr.png'

//API
import { queryAllTrips, deleteTrip } from '../api/services/programmerAPI';


function createData(matricula, lugar_partida, hora_partida, lugar_llegada, hora_llegada, dias, protein) {
    return { matricula, lugar_partida, hora_partida, lugar_llegada, hora_llegada, dias, protein };
}

/*
const rows = [
    createData('SH747GD', 'Colima', '12:00', 'Cuauhtemoc', '13:00', '2023-05-27 ~ 2023-06-22'),
    createData('RFERR44', 'Cuauhtémoc', '14:00', 'Colima', '13:00', '2023-05-27 ~ 2023-06-22'),
    createData('HGTYD6', 'Colima 1', '12:00', 'Cuauhtemoc', '13:00', '2023-05-27 ~ 2023-06-22'),
  ];*/





  


const Page2 = () => {

    const navigate = useNavigate();
    const [rows, setRows] = useState([])

    const [globalError, setGlobalError] = useState({});
    const [open, setOpen] = useState(false);

    // Functions
  const fetchTrips = async () => {
    //setIsLoading(true)

    try {
      const response = await queryAllTrips()
       console.log(response)
      setRows(response.data.data)
    } catch (err) {
      //setMessage({ open: true, msg: err.data.msg, key: new Date() })
      console.log(err)
    }
    
    //setIsLoading(false)
  }

   // Handle for close
   const handleClose = () => {

    setOpen(false)

    };

  const deleteTripById=async(idtrip)=>{
    try{
        const objDelete = {
            idviaje:idtrip
        }
        let responseDelete = await deleteTrip(objDelete)
        setGlobalError({ open: true, msg: responseDelete.data.msg, key: new Date(), status:responseDelete.data.status })
        setOpen(true)  

        setTimeout(()=> {
            window.location.reload(true)
           }, 1500);

    }catch(error){
        setGlobalError({ open: true, msg: error.data.msg, key: new Date(), status:error.data.status })
        setOpen(true) 
            //return error

    }

  }

  useEffect(() => {
    fetchTrips()
    
  }, []);

    return (
        <>
            <NavBar />
            
            <Grid container spacing={2} justifyContent="end" style={{marginBottom: '20px'}}>
                <Grid item md={3}>
                    <Button variant="contained" color="success" onClick={() => navigate('/page4')}>+ Añadir viaje</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Placa</TableCell>
                                <TableCell>Lugar de partida</TableCell>
                                <TableCell align="right">Hora de partida</TableCell>
                                <TableCell align="right">Lugar de llegada</TableCell>
                                <TableCell align="right">Hora de llegada</TableCell>
                                <TableCell align="right">Fecha</TableCell>
                                <TableCell align="right">Editar</TableCell>
                                <TableCell align="right">Borrar</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.idviaje}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.placa}
                                </TableCell>
                                <TableCell align="right">{row.partida}</TableCell>
                                <TableCell align="right">{row.hora_de_partida}</TableCell>
                                <TableCell align="right">{row.destino}</TableCell>
                                <TableCell align="right">{row.hora_de_llegada}</TableCell>
                                <TableCell align="right">{row.fecha.split('T')[0]}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => navigate('/edittrip',{
                                            state: {
                                              trip:row
                                            }
                                          })}
                                    >
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={()=>{deleteTripById(row.idviaje)}}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>  

                <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        
      >
        <Alert severity={globalError.status} variant="filled" sx={{ width: '100%' }}>{globalError.msg}</Alert>
      </Snackbar>

            </Grid>
        </>
    )

}

export default Page2;