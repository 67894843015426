export const validateLogin = (userData, setIsLoggedIn, setRender) => {
    if(userData.status && userData.status !== 'loading'){
      // // console.log(userData, 'Validacion')
      if(
        userData.status === 'success' &&
        userData.id !== '' &&
        userData.matricula !== ''
        
      ){
        setIsLoggedIn(true)
      }
      else setIsLoggedIn(false)
  
      setRender(true)
    }
  }