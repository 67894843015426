import { useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

// MUI Stuff
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

//Icons
import MenuIcon from '@mui/icons-material/Menu';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import CellTowerIcon from '@mui/icons-material/CellTower';
import PersonIcon from '@mui/icons-material/Person';
import ArchiveIcon from '@mui/icons-material/Archive';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HouseIcon from '@mui/icons-material/House';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ForumIcon from '@mui/icons-material/Forum';

import CarCrashIcon from '@mui/icons-material/CarCrash';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BadgeIcon from '@mui/icons-material/Badge';

import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';

const MenuListItem = (props) => {
  const { Icon, title, to, path } = props
  const match = useMatch({ path })

  return (
    <MenuItem
      onClick={() => to()}
      selected={Boolean(match)}
      sx={{
        borderLeft: (theme) =>
          match
          ? `3px solid ${theme.palette.secondary.light}`
          : `3px solid transparent`
        ,
      }}
    >
      <ListItemIcon
        sx={{
          color: (theme) =>
            match
            ? theme.palette.secondary.light
            : 'rgba(0, 0, 0, 0.54)'
          ,
        }}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText
        sx={{
          color: (theme) =>
            match
            ? theme.palette.secondary.light
            : 'inherit'
          ,
        }}
        primary={title}
      />
    </MenuItem>
  );
}

const RenderMobileMenu = (props) =>{
  const { level } = props
  const navigate = useNavigate()


  return (
    <>
      
      {level === 0 &&
        <>

  <MenuListItem
            Icon={ConfirmationNumberIcon}
            title="Mis boletos"
            path="/page6"
            to={() => navigate('/page6')}
        />
         <MenuListItem
            Icon={BrowseGalleryIcon}
            title="Consultar Disponibilidad"
            path="/page9"
            to={() => navigate('/page9')}
        />
        
        
        
        </>
      }

      {level === 1 &&
        <>
        <MenuListItem
            Icon={CropFreeIcon}
            title="Escanear"
            path="/page1"
            to={() => navigate('/page1')}
        />
        
        </>
      }

      {level === 2 &&
        <>
       <MenuListItem
            Icon={CalendarMonthIcon}
            title="Horarios"
            path="/page2"
            to={() => navigate('/page2')}
        />
        <MenuListItem
            Icon={DescriptionIcon}
            title="Resúmenes"
            path="/page7"
            to={() => navigate('/page7')}
        />
        </>
      }

      {level === 3 &&
        <>
        <MenuListItem
            Icon={EventSeatIcon}
            title="Reservar"
            path="/page5"
            to={() => navigate('/page5')}
        />
        <MenuListItem
            Icon={BrowseGalleryIcon}
            title="Consultar Disponibilidad"
            path="/page8"
            to={() => navigate('/page8')}
        />
        <MenuListItem
            Icon={DescriptionIcon}
            title="Resúmenes"
            path="/page7"
            to={() => navigate('/page7')}
        />
        
        </>
      }
      {level === 4 &&
        <>
        <MenuListItem
            Icon={SchoolIcon}
            title="Estudiantes"
            path="/page5"
            to={() => navigate('/depurar/0')}
        />
        <MenuListItem
            Icon={SupervisorAccountIcon}
            title="Administrador de reserva"
            path="/page8"
            to={() => navigate('/depurar/3')}
        />
        <MenuListItem
            Icon={DirectionsBusIcon}
            title="Operadores"
            path="/page7"
            to={() => navigate('/depurar/1')}
        />
         <MenuListItem
            Icon={SettingsIcon}
            title="Administrador de servicio"
            path="/page7"
            to={() => navigate('/depurar/2')}
        />
        
        </>
        }

    </>
  );
}


const MobileMenu = (props) => {
  const { level } = props
  const navigate = useNavigate()
  
  const [anchorElNav, setAnchorElNav] = useState(null)


  // Functions
  const handleMobileMenuOpen = (event) => {
    setAnchorElNav(event.currentTarget);
  }
  const handleMobileMenuClose = () => {
    setAnchorElNav(null);
  }


  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: {
            xs: 'flex',
            md: 'none',
          },
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Menu
          keepMounted
          id="menu-appbar"
          anchorEl={anchorElNav}
          TransitionComponent={Fade}
          open={Boolean(anchorElNav)}
          onClose={handleMobileMenuClose}
          onClick={handleMobileMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <RenderMobileMenu level={level} />
        </Menu>
      </Box>

      <Typography
        noWrap
        variant="h6"
        component="div"
        onClick={() => navigate('/home')}
        sx={{
          flexGrow: 1,
          display: { xs: 'flex', md: 'none' },
          color:  (theme) => theme.palette.secondary.dark,
        }}
      >
        Ruta <span style={{fontWeight: 100}}>ESCOLAR</span>
      </Typography>
    </>
  );
}

export default MobileMenu;