import dataUsers from '../data.json'

import axios, { instanceI as axiosImages } from '../axiosConfig';

  


export const userInfo = async() => {
    return await axios.get(`/user/getuserinfo`)
  }
export const userInfoSession = async() => {
  return await axios.get(`/user/getusersession`)
}
  export const tripAvailabilityTableStudent = async () => {
    return await axios.get(`/user/availabilitytablestudent`)
  }

  export const tripAvailabilityTable = async () => {
    return await axios.get(`/user/availabilitytable`)
  }


export const login = async(data) => {
    // console.log(data)
    // return dataUsers.users.find(user => user.email === data.email && user.password === data.password)
    return await axios.post(`/user/login`, data)
}
export const signUpStudent = async(data) =>{
  return await axios.post(`/user/signupstudent`, data)
}
export const signUpAdmin = async(data) =>{
  return await axios.post(`/user/signupadmin`, data)
}



export const getMyTickets = async() =>{
  return await axios.get(`/user/mytickets`)
}

export const getMyReservations = async() =>{
  return await axios.get(`/user/myreservations`)
}

export const logout = async () => {
  return await axios.get(`/user/logoff`)
}
